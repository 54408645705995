import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import LogoWhiteSvg from 'assets/logo-white.svg';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'router';

const cn = classNamesBind.bind(styles);

const Footer = () => {
  const { t } = useTranslation();
  const navLinkClassName: NavLinkProps['className'] = ({ isActive }) =>
    cn('footer__link', {
      'footer__link--active': isActive,
    });

  return (
    <footer
      className={classNames(cn('footer-wrapper'), 'wrapper wrapper--black-bg')}
    >
      <div className={cn('footer')}>
        <section>
          <img src={LogoWhiteSvg} alt="Logo white" />
          <div>
            <NavLink className={navLinkClassName} to={PATHS.privacy}>
              {t('components.footer.menu.privacy')}
            </NavLink>
            <NavLink className={navLinkClassName} to={PATHS.terms}>
              {t('components.footer.menu.terms')}
            </NavLink>
            <NavLink className={navLinkClassName} to={PATHS.about}>
              {t('components.footer.menu.about')}
            </NavLink>
          </div>
        </section>
        <hr />
        <p>{t('components.footer.copyrightText')}</p>
      </div>
    </footer>
  );
};

export default Footer;
