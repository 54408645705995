import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'router';
import BlueStar from 'assets/blue-star.svg';
import { HighlightedText, Search } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const BrowseBlock = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="wrapper wrapper--black-bg">
      <div className={cn('browse-block')}>
        <HighlightedText tag="h1">
          {t('pages.categories.browseCategories')}
        </HighlightedText>
        <Search onSubmit={(values) => navigate(PATHS.getSearch(values))} />
        <img src={BlueStar} />
      </div>
    </div>
  );
};

export default BrowseBlock;
