import React, { Dispatch, SetStateAction, FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import { TextArea } from 'components/form';
import { CircularIndicator } from 'components/uikit';
import { useOutsideTap } from 'hooks';

import useReplyForm from './useReplyForm';
import { ReplyButtonProps } from './ReplyButton';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type ReplyDialogProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onReply: ReplyButtonProps['onReply'];
};

const ReplyDialog: FC<ReplyDialogProps> = ({ setIsOpen, onReply }) => {
  const { t } = useTranslation();
  const ref = useOutsideTap<HTMLFormElement>(true, () => setIsOpen(false));
  const { control, onSubmit, isButtonDisabled, isSubmitting } = useReplyForm({
    setIsOpen,
    onReply,
  });

  return (
    <div className={cn('reply-dialog')}>
      <form onSubmit={onSubmit} ref={ref}>
        <div className={cn('reply-dialog__header')}>
          <h5>{t('components.review.yourReplyToReview')}</h5>
          <CgClose size={32} onClick={() => setIsOpen(false)} />
        </div>
        <div>
          <TextArea
            name="comment"
            control={control}
            componentProps={{
              placeholder: t('components.review.yourReply'),
            }}
          />
        </div>
        <button
          type="submit"
          className="button--primary"
          disabled={isButtonDisabled}
        >
          {t('actions.apply')}
          {isSubmitting && <CircularIndicator />}
        </button>
      </form>
    </div>
  );
};

export default ReplyDialog;
