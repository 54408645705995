import { Review, Paginate, PaginationParams, CreateReviewBody } from 'models';
import { ApiResponse } from 'api';

import http from '../http';

export type GetReviewsReturn = ApiResponse<Paginate<Review>>;
export type GetReviewsParams = PaginationParams;
export enum ReviewSortBy {
  helpful = 'helpful',
  recent = 'recent',
  positive = 'positive',
  negative = 'negative',
}
export type GetMyReviewsParams = PaginationParams & {
  sort?: ReviewSortBy;
};
export type GetCreatorReviewsParams = GetMyReviewsParams & {
  username: string;
};
export type CreateReviewReturn = Review | null;
export type CreateReplyBody = { reviewId: string; comment: string };

class ReviewsApi {
  recent = async (params: GetReviewsParams): Promise<GetReviewsReturn> =>
    http.fetch((axios, signal) =>
      axios.get('/reviews/recent', {
        signal,
        params: { limit: 10, offset: 0, ...params },
      })
    );

  creatorReviews = async (
    params: GetCreatorReviewsParams,
    showError = true
  ): Promise<GetReviewsReturn> =>
    http.fetch(
      (axios, signal) =>
        axios.get(`/reviews/creator/${params.username}`, {
          signal,
          params: {
            limit: 10,
            offset: 0,
            sort: ReviewSortBy.recent,
            ...params,
          } as GetCreatorReviewsParams,
        }),
      showError
    );

  myReviewsForOthers = async (
    params?: GetMyReviewsParams
  ): Promise<GetReviewsReturn> =>
    http.fetch((axios, signal) =>
      axios.get(`/reviews/my`, {
        signal,
        params: {
          limit: 10,
          offset: 0,
          sort: ReviewSortBy.recent,
          ...params,
        } as GetMyReviewsParams,
      })
    );

  reviewsLeftForMe = async (
    params?: GetMyReviewsParams
  ): Promise<GetReviewsReturn> =>
    http.fetch((axios, signal) =>
      axios.get(`/reviews/my-creator`, {
        signal,
        params: {
          limit: 10,
          offset: 0,
          sort: ReviewSortBy.recent,
          ...params,
        } as GetMyReviewsParams,
      })
    );

  create = async (body: CreateReviewBody): Promise<CreateReviewReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/reviews', body, {
        signal,
      })
    );

  reply = async ({
    reviewId,
    comment,
  }: CreateReplyBody): Promise<CreateReviewReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.patch(
        `/reviews/reaction/${reviewId}`,
        {
          comment,
        },
        {
          signal,
        }
      )
    );

  helpful = async (reviewId: string): Promise<CreateReviewReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.patch(
        `/reviews/helpful/${reviewId}`,
        {},
        {
          signal,
        }
      )
    );

  unhelpful = async (reviewId: string): Promise<CreateReviewReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.delete(`/reviews/helpful/${reviewId}`, {
        signal,
      })
    );
}

export const reviewsApi = new ReviewsApi();
