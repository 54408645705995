import React, { Fragment } from 'react';

import {
  WelcomeBlock,
  BetweenBlock,
  WhatWeOfferBlock,
  CoreFeaturesBlock,
  ProtectingAndPrivacyBlock,
  CommunityBlock,
} from './components';

const About = () => {
  return (
    <Fragment>
      <WelcomeBlock />
      <BetweenBlock />
      <WhatWeOfferBlock />
      <CoreFeaturesBlock />
      <ProtectingAndPrivacyBlock />
      <CommunityBlock />
    </Fragment>
  );
};

export default About;
