import React, { FC, InputHTMLAttributes, ReactElement, useState } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import LogoWhiteSvg from 'assets/logo-white.svg';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type BaseImageProps = InputHTMLAttributes<HTMLImageElement>;
export type ImageProps = Omit<BaseImageProps, 'src'> & {
  src: BaseImageProps['src'] | null;
  className?: string;
  emptyOrErrorComponent?: ReactElement;
};

const Image: FC<ImageProps> = (props) => {
  const { src, className, emptyOrErrorComponent, ...other } = props;
  const [isError, setIsError] = useState(false);

  const onError = () => setIsError(true);

  if (!src || isError) {
    return (
      <div className={classNames(cn('image-error'), className)} {...other}>
        {emptyOrErrorComponent ? (
          emptyOrErrorComponent
        ) : (
          <img src={LogoWhiteSvg} alt="Logo white" />
        )}
      </div>
    );
  }

  return (
    <img
      src={src}
      onError={onError}
      alt="Image"
      className={classNames(cn('image'), className)}
      {...other}
    />
  );
};

export default Image;
