import { Platforms } from 'models';
import { PaginationParams } from './common';

export enum CreatorSort {
  relevant = 'relevant',
  liked = 'liked',
  rated = 'rated',
  subscribers = 'subscribers',
  reviews = 'reviews',
  verified = 'verified',
}

export enum CreatorRating {
  any = 'any',
  twoPlus = '2+',
  threePlus = '3+',
  fourPlus = '4+',
  fivePlus = '5+',
}

export enum CreatorPrice {
  any = 'any',
  freeOnly = 'free only',
  fiveAndUnder = '$5 and under',
  fiveToFifteen = '$5 to $15',
  fifteenAndOver = '$15 and over',
}

export enum CreatorGender {
  all = 'all',
  female = 'female',
  male = 'male',
  unknown = 'unknown',
}

export type CreatorSearchQueryParams = PaginationParams &
  Partial<{
    search: string;
    category: string;
    platform: Platforms;
    nsfw: boolean;
    sort: CreatorSort;
    rating: CreatorRating;
    price: CreatorPrice;
    gender: CreatorGender;
    location: string;
    // lat, long. Example: 50.02588, 36.22675
    coordinates: string;
  }>;

export const CreatorSearchQueryParamsKeys: Record<
  keyof CreatorSearchQueryParams,
  keyof CreatorSearchQueryParams
> = {
  search: 'search',
  category: 'category',
  platform: 'platform',
  nsfw: 'nsfw',
  limit: 'limit',
  offset: 'offset',
  sort: 'sort',
  rating: 'rating',
  price: 'price',
  gender: 'gender',
  location: 'location',
  coordinates: 'coordinates',
};

export type CreatorMedia = {
  avatar: string | null;
  avatarTags: string[] | null;
  header: string | null;
  avatarWidth: number | null;
  avatarHeight: number | null;
};

export type CreatorCounters = {
  subscribers: number | null;
  likes: number | null;
  favorites: number | null;
  medias: number | null;
  photos: number | null;
  posts: number | null;
  videos: number | null;
  other: {
    average: {
      value: number;
    } | null;
    highest: {
      value: number;
    } | null;
  } | null;
};

export type CreatorLinks = {
  website: string | null;
  wishlist: string | null;
  facebook: string | null;
  instagram: string | null;
  twitch: string | null;
  twitter: string | null;
  youtube: string | null;
  patreon: string | null;
  graphtreon: string | null;
};

export type CreatorLinksKeys = keyof CreatorLinks;

export type CreatorReviewsStatistic = {
  rating: number | null;
  reviewCount: number | null;
  ratingBreakdown: {
    1: number | null;
    2: number | null;
    3: number | null;
    4: number | null;
    5: number | null;
  };
};

export type Creator = {
  id: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  price: number | null;
  promoPrice: number | null;
  promoOff: number | null;
  username: string | null;
  name: string | null;
  bio: string | null;
  platform: Platforms | null;
  rating: number | null;
  media: CreatorMedia | null;
  counters: CreatorCounters | null;
};

export type DetailedCreator = Creator & {
  favorite: boolean | null;
  reviewed: boolean | null;
  links: CreatorLinks | null;
  url: string | null;
  reviewsStatistic: CreatorReviewsStatistic | null;
};
