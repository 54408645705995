import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { HighlightedText } from 'components/uikit';

import PaginatedReviewsForOthersList from './ReviewsForOthersList';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ReviewsForOthers = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <div className={cn('reviews')}>
        <HighlightedText tag="h3">
          {t('pages.reviewsForOthers.title')}
        </HighlightedText>
        <PaginatedReviewsForOthersList />
      </div>
    </div>
  );
};

export default ReviewsForOthers;
