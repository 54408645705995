import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { IoIosStar } from 'react-icons/io';

import { formatNumber } from 'utils';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type StarStatisticProps = {
  star: number;
  total: number;
  current: number;
};

const procentFrom = (val: number, from: number): number =>
  Math.round((val / from) * 100);

const StarStatistic: FC<StarStatisticProps> = ({ star, total, current }) => {
  const procent = procentFrom(current, total) || 0;

  return (
    <div className={cn('star')}>
      <IoIosStar size={22} color="orange" />
      <p>{star}</p>
      <span>
        <span
          style={{
            width: `${procent}%`,
          }}
        />
      </span>
      <p>{formatNumber(current)}</p>
    </div>
  );
};

export default StarStatistic;
