import useFetch from './useFetch';

interface FetchResult<ReturnT> {
  isLoading: boolean;
  htmlContent: ReturnT | null;
}

function useLoadHtmlTemplate(filePath: string): FetchResult<string> {
  const loadHtml = async (filePath: string): Promise<string> => {
    try {
      const response: Response = await fetch(filePath);
      const html: string = await response.text();

      return html;
    } catch (error) {
      console.error(`Failed to load HTML file from ${filePath}`, error);
      throw new Error('Failed to load HTML');
    }
  };

  const { data, isLoading } = useFetch<string, string>(loadHtml, {
    instantFetch: true,
    args: filePath,
  });

  return { htmlContent: data, isLoading };
}

export default useLoadHtmlTemplate;
