import React, { TextareaHTMLAttributes, forwardRef } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { InputProps } from 'types';

const cn = classNamesBind.bind(styles);

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  InputProps;

// eslint-disable-next-line react/display-name
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const { label, className, error, ...rest } = props;

    return (
      <div className={classNames(cn('text-area', className))}>
        {label && <label>{label}</label>}
        <textarea {...rest} ref={ref} />
        {error && <p className="error-text">{error}</p>}
      </div>
    );
  }
);

export default TextArea;
