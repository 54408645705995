import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'router';
import { useAuthStore } from 'stores';
import { BREAKPOINTS } from 'styles/vars';
import { useDisableOverflowY, useOutsideTap, useWindowDimensions } from 'hooks';

import Avatar, { AuthMenuItems } from '../Avatar';
import { navLinkClassName } from './helpers';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type MenuProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const Menu: FC<MenuProps> = ({ isOpen, setIsOpen }) => {
  const close = () => setIsOpen(false);
  const [avatarMenuIsOpen, setAvatarMenuIsOpen] = useState(false);
  const ref = useOutsideTap<HTMLElement>(isOpen, close);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  useDisableOverflowY(isOpen);

  const isAuth = useAuthStore((state) => !!state.accessToken);
  const showAuthLinksInMenu = isAuth && width < BREAKPOINTS.L;

  return (
    <section
      ref={ref}
      className={cn('menu', {
        'menu--active': isOpen,
      })}
    >
      <div>
        <NavLink
          className={navLinkClassName}
          onClick={close}
          to={PATHS.categories}
        >
          {t('components.header.menu.categories')}
        </NavLink>
        <NavLink className={navLinkClassName} onClick={close} to={PATHS.about}>
          {t('components.header.menu.about')}
        </NavLink>
        <NavLink
          className={navLinkClassName}
          onClick={close}
          to={PATHS.creators}
        >
          {t('components.header.menu.forCreators')}
        </NavLink>
        {showAuthLinksInMenu && <AuthMenuItems close={close} />}
      </div>
      {isAuth ? (
        <Avatar isOpen={avatarMenuIsOpen} setIsOpen={setAvatarMenuIsOpen} />
      ) : (
        <div>
          <Link
            onClick={close}
            to={PATHS.signIn}
            className="button button--secondary"
          >
            {t('actions.login')}
          </Link>
          <Link
            onClick={close}
            to={PATHS.signUp}
            className="button button--primary"
          >
            {t('actions.signUp')}
          </Link>
        </div>
      )}
    </section>
  );
};

export default Menu;
