import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useFetchReviewsLeftForMe, useMyReviewsStore } from 'stores';
import { Review, Paginate } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type ReviewsLeftForMeListProps = ReturnType<typeof useFetchReviewsLeftForMe>;

const ReviewsLeftForMeList: FC<ReviewsLeftForMeListProps> = ({
  data,
  isLoading,
}) => {
  const { t } = useTranslation();
  const createReply = useMyReviewsStore((state) => state.createReply);

  if (isLoading) {
    return (
      <div className={cn('reviews__list')}>
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
      </div>
    );
  }

  if (data === null || data.count === 0) {
    return <h5>{t('noData.reviewsLeftForMe')}</h5>;
  }
  const items = data.results;

  return (
    <div className={cn('reviews__list')}>
      {items.map((review) => (
        <Review
          key={review.id}
          canRate={false}
          review={review}
          onReply={async (data) => {
            if (review.id) {
              await createReply({ ...data, reviewId: review.id });
            }
          }}
        />
      ))}
    </div>
  );
};

const PaginatedReviewsLeftForMeList = () => {
  const { data, isLoading } = useFetchReviewsLeftForMe();

  const handlePageClick = ({ selected }: { selected: number }) => {
    const store = useMyReviewsStore.getState();
    const limit = store.reviewsForOthers?.limit || 0;
    const offset = limit * selected;

    store.fetchReviewsLeftForMe({ offset }, { showLoader: false });
  };

  return (
    <div>
      <ReviewsLeftForMeList data={data} isLoading={isLoading} />
      <Paginate onPageChange={handlePageClick} itemsCount={data?.count || 0} />
    </div>
  );
};

export default PaginatedReviewsLeftForMeList;
