import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { reviewsApi, GetMyReviewsParams, CreateReplyBody } from 'api';
import { CreateReviewBody, Paginate, Review } from 'models';
import { FetchOptions } from 'types';

type State = {
  reviewsForOthers: Paginate<Review> | null;
  reviewsLeftForMe: Paginate<Review> | null;
  isLoading: boolean;
};

type Actions = {
  clear: () => void;
  fetchReviewsForOthers: (
    params?: GetMyReviewsParams,
    oprions?: FetchOptions
  ) => Promise<void>;
  fetchReviewsLeftForMe: (
    params?: GetMyReviewsParams,
    oprions?: FetchOptions
  ) => Promise<void>;
  setLoading: (value: boolean) => void;
  setReviewsForOthers: (favorites: Paginate<Review>) => void;
  setReviewsLeftForMe: (favorites: Paginate<Review>) => void;
  createReview: (body: CreateReviewBody) => Promise<boolean>;
  createReply: (body: CreateReplyBody) => Promise<void>;
};

export type MyReviewsStore = State & Actions;

const defaultState: State = {
  reviewsForOthers: null,
  reviewsLeftForMe: null,
  isLoading: false,
};

const useMyReviewsStore = create<MyReviewsStore>()(
  immer((set, get) => ({
    ...defaultState,

    clear: () => set(() => defaultState),

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setReviewsForOthers: (reviews) =>
      set((state) => {
        state.reviewsForOthers = reviews;
      }),

    setReviewsLeftForMe: (reviews) =>
      set((state) => {
        state.reviewsLeftForMe = reviews;
      }),

    fetchReviewsForOthers: async (params, { showLoader = true } = {}) => {
      const { setLoading, setReviewsForOthers } = get();

      if (showLoader) setLoading(true);

      const res = await reviewsApi.myReviewsForOthers(params);

      if (showLoader) setLoading(false);

      if (res.data !== null) {
        setReviewsForOthers(res.data);
      }
    },

    fetchReviewsLeftForMe: async (params, { showLoader = true } = {}) => {
      const { setLoading, setReviewsLeftForMe } = get();

      if (showLoader) setLoading(true);

      const res = await reviewsApi.reviewsLeftForMe(params);

      if (showLoader) setLoading(false);

      if (res.data !== null) {
        setReviewsLeftForMe(res.data);
      }
    },

    createReview: async (body) => {
      const res = await reviewsApi.create(body);

      if (res === null) return false;

      await get().fetchReviewsForOthers(undefined, { showLoader: false });

      return true;
    },

    createReply: async (body) => {
      const res = await reviewsApi.reply(body);

      if (res === null) return;

      await get().fetchReviewsLeftForMe(undefined, { showLoader: false });
    },
  }))
);

export const useFetchReviewsForOthers = () => {
  const data = useMyReviewsStore((state) => state.reviewsForOthers);
  const isLoading = useMyReviewsStore((state) => state.isLoading);

  useEffect(() => {
    if (data === null) {
      useMyReviewsStore.getState().fetchReviewsForOthers();
    }
  }, []);

  return { data, isLoading };
};

export const useFetchReviewsLeftForMe = () => {
  const data = useMyReviewsStore((state) => state.reviewsLeftForMe);
  const isLoading = useMyReviewsStore((state) => state.isLoading);

  useEffect(() => {
    if (data === null) {
      useMyReviewsStore.getState().fetchReviewsLeftForMe();
    }
  }, []);

  return { data, isLoading };
};

export default useMyReviewsStore;
