import React, {
  forwardRef,
  InputHTMLAttributes,
  ChangeEventHandler,
  useRef,
} from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

import { OptionWithIcon, InputProps } from 'types';

import ToggleButton, { ToggleButtonProps } from '../ToggleButton';

export type ToggleButtonGroupProps = InputHTMLAttributes<HTMLInputElement> &
  Pick<InputProps, 'className' | 'error'> & {
    items: OptionWithIcon[];
    type?: ToggleButtonProps['type'];
    onHandleChange?: (values: string[]) => void;
  };

// eslint-disable-next-line react/display-name
const ToggleButtonGroup = forwardRef<HTMLInputElement, ToggleButtonGroupProps>(
  (props, ref) => {
    const {
      items,
      error,
      onChange,
      className,
      onHandleChange,
      type = 'checkbox',
      defaultValue,
      ...inputProps
    } = props;
    const values = useRef<Set<string>>(new Set());

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange && onChange(e);

      if (onHandleChange) {
        const {
          target: { checked, value },
        } = e;
        const singleChoice = type === 'radio';

        if (singleChoice) {
          if (checked) {
            values.current = new Set([value]);
          }
        } else {
          if (checked) {
            values.current = new Set([...values.current, value]);
          } else {
            values.current = new Set(
              [...values.current].filter((x) => x !== value)
            );
          }
        }

        onHandleChange(Array.from(values.current));
      }
    };

    return (
      <div className={classNames(cn('toggle-button-group'), className)}>
        <div>
          {items.map(({ value, label, icon }) => (
            <ToggleButton
              {...inputProps}
              defaultChecked={defaultValue === value}
              onChange={onChangeHandler}
              ref={ref}
              value={value}
              label={label}
              key={value}
              leftEl={icon}
              type={type}
            />
          ))}
        </div>
        {error && <p className="error-text">{error}</p>}
      </div>
    );
  }
);

export default ToggleButtonGroup;
