import { queryString } from 'utils';
import { CreatorSearchQueryParams } from 'models';
import { ErrorPageQueryParams } from 'modules/Error';

class Paths {
  categories = '/categories';
  categoryKey = ':categoryId';

  creators = '/creators';
  creatorKey = ':creatorUsername';
  getCreator = (id: string): string => `/${id}`;

  search = '/search';
  getSearch = (params: CreatorSearchQueryParams): string =>
    `${this.search}?${queryString.stringify(params)}`;

  privacy = '/privacy';

  terms = '/terms';

  about = '/about';

  myProfile = '/my-profile';
  myFavorites = '/my-favorites';

  reviewsKey = '/reviews';
  reviewsForOthersKey = 'for-others';
  reviewsForOthers = `${this.reviewsKey}/${this.reviewsForOthersKey}`;
  reviewsLeftForMeKey = 'left-for-me';
  reviewsLeftForMe = `${this.reviewsKey}/${this.reviewsLeftForMeKey}`;

  verification = '/verification';
  verificationSuccessKey = 'success';
  verificationSuccess = `${this.verification}/${this.verificationSuccessKey}`;
  verificationErrorKey = 'error';
  verificationError = `${this.verification}/${this.verificationErrorKey}`;

  signIn = '/sign-in';
  signUp = '/sign-up';
  resetPassword = '/reset-password';
  forgotPassword = '/forgot-password';

  error = '/error';
  getError = (params: ErrorPageQueryParams): string =>
    `${this.error}?${queryString.stringify(params)}`;
}

const PATHS = new Paths();

export default PATHS;
