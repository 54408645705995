import React from 'react';
import { IoStarOutline, IoStar } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import { getPlatformWhiteLogo } from 'utils';
import { useProfileStore } from 'stores';
import { Platforms } from 'models';

export type FavoriteAndViewButtonsProps = {
  isFavorite: boolean;
  platform: Platforms | null;
  toggleFavorite?: () => Promise<void>;
  url: string | null;
};

const FavoriteAndViewButtons: React.FC<FavoriteAndViewButtonsProps> = ({
  toggleFavorite,
  isFavorite,
  platform,
  url,
}) => {
  const { t } = useTranslation();
  const isAuth = useProfileStore((state) => !!state.profile?.id);

  return (
    <div>
      {isAuth && toggleFavorite && (
        <button
          type="button"
          onClick={toggleFavorite}
          className="button--secondary"
        >
          {isFavorite ? (
            <IoStar size={20} color="orange" />
          ) : (
            <IoStarOutline size={20} />
          )}
          <p>
            {isFavorite
              ? t('pages.creator.actions.removeFromFavorites')
              : t('pages.creator.actions.addToFavorites')}
          </p>
        </button>
      )}
      {platform && url && (
        <a
          href={url}
          target="_blank"
          className="button--primary"
          rel="noreferrer"
        >
          {getPlatformWhiteLogo(platform)}
          <p>{t('pages.creator.actions.view', { platform })}</p>
        </a>
      )}
    </div>
  );
};

export default FavoriteAndViewButtons;
