import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { useQueryString } from 'hooks';
import { creatorsApi, GetCreatorsParams, GetCreatorsReturn } from 'api';
import { FetchOptions } from 'types';

type SearchCreatorsState = {
  isFetching: boolean;
  creators: GetCreatorsReturn;
};

type SearchCreatorsActions = {
  fetch: (params: GetCreatorsParams, options?: FetchOptions) => Promise<void>;
};

export type SearchCreatorsStore = SearchCreatorsState & SearchCreatorsActions;

const useSearchCreatorsStore = create<SearchCreatorsStore>()(
  immer((set) => ({
    creators: null,
    isFetching: false,

    fetch: async (params, { showLoader = true } = {}) => {
      if (showLoader) {
        set(() => ({ isFetching: true }));
      }

      const res = await creatorsApi.getList(params);

      if (showLoader) {
        set(() => ({ isFetching: false }));
      }

      if (res === null) return;

      set(() => ({ creators: res }));
    },
  }))
);

export const useFetchCreatorsWhenQueryParamsChange = () => {
  const { params } = useQueryString();

  useEffect(() => {
    useSearchCreatorsStore.getState().fetch(params);
  }, [params]);
};

export default useSearchCreatorsStore;
