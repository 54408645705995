import React, { ReactElement } from 'react';
import { SiOnlyfans } from 'react-icons/si';
import { PiTwitchLogoFill, PiPatreonLogoFill } from 'react-icons/pi';

import { Platforms } from 'models';
import OnlyFans from 'assets/only-fans.svg';
import Patreon from 'assets/patreon.svg';
import Twitch from 'assets/twitch.svg';

export const getPlatformColorLogo = (platform: Platforms): string => {
  switch (platform) {
    case Platforms.onlyFans:
      return OnlyFans;
    case Platforms.patreon:
      return Patreon;
    case Platforms.twitch:
      return Twitch;
    default:
      return OnlyFans;
  }
};

export const getPlatformWhiteLogo = (platform: Platforms): ReactElement => {
  switch (platform) {
    case Platforms.onlyFans:
      return <SiOnlyfans color="white" size={20} />;
    case Platforms.patreon:
      return <PiPatreonLogoFill color="white" size={20} />;
    case Platforms.twitch:
      return <PiTwitchLogoFill color="white" size={20} />;
    default:
      return <SiOnlyfans color="white" size={20} />;
  }
};
