import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'router';
import { TextInput } from 'components/form';
import { CircularIndicator } from 'components/uikit';

import useSignUpInForm from './useSignUpInForm';

type SignUpInFormProps = {
  isSignIn: boolean;
};

const SignUpInForm: FC<SignUpInFormProps> = ({ isSignIn }) => {
  const { t } = useTranslation();
  const { onSubmit, isButtonDisabled, isSubmitting, control } = useSignUpInForm(
    { isSignIn }
  );

  return (
    <form onSubmit={onSubmit}>
      {!isSignIn && (
        <TextInput
          name="name"
          control={control}
          componentProps={{
            type: 'text',
            underlined: true,
            placeholder: t('inputs.username'),
          }}
        />
      )}
      <TextInput
        name="email"
        control={control}
        componentProps={{
          type: 'email',
          underlined: true,
          placeholder: t('inputs.email'),
        }}
      />
      <TextInput
        name="current-password"
        control={control}
        componentProps={{
          type: 'password',
          underlined: true,
          placeholder: t('inputs.password'),
        }}
      />
      {!isSignIn && (
        <TextInput
          name="confirm-password"
          control={control}
          componentProps={{
            type: 'password',
            underlined: true,
            placeholder: t('pages.resetPassword.repeatPassword'),
          }}
        />
      )}
      {isSignIn && (
        <Link to={PATHS.forgotPassword}>{t('pages.forgotPassword.title')}</Link>
      )}
      <button
        disabled={isButtonDisabled}
        type="submit"
        className="button--primary"
      >
        {isSignIn ? t('actions.login') : t('actions.signUp')}
        {isSubmitting && <CircularIndicator />}
      </button>
    </form>
  );
};

export default SignUpInForm;
