import React, { useState } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCategoriesStore } from 'stores';
import { Switch, CategoryTag } from 'components/uikit';
import { PATHS } from 'router';

import styles from './styles.module.scss';
import Skeleton from 'react-loading-skeleton';

const cn = classNamesBind.bind(styles);

const ViewAllButton = () => {
  const { t } = useTranslation();

  return <Link to={PATHS.categories}>{t('actions.viewAll')}</Link>;
};

type CategoriesListProps = { nsfc: boolean };

const CategoriesList: React.FC<CategoriesListProps> = ({ nsfc }) => {
  const { t } = useTranslation();
  const categories = useCategoriesStore((state) => {
    const categories = state.data ?? [];
    const filteredCategories = nsfc
      ? categories
      : categories.filter((el) => !el.nsfw);
    return filteredCategories.slice(0, 30);
  });
  const isLoading = useCategoriesStore((state) => state.isLoading);

  if (isLoading) {
    return (
      <div className={cn('categories-block__content')}>
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} width={150} height={50} borderRadius={32} />
        ))}
      </div>
    );
  }

  if (!categories || categories?.length === 0) {
    return <h5>{t('noData.common')}</h5>;
  }

  return (
    <div className={cn('categories-block__content')}>
      {categories.map(({ icon, name }) => (
        <CategoryTag
          to={PATHS.getSearch({ category: name })}
          key={name}
          emoji={icon}
        >
          {name}
        </CategoryTag>
      ))}
    </div>
  );
};

const BrowseByBlock = () => {
  const { t } = useTranslation();
  const [nsfc, setNsfc] = useState(false);

  return (
    <div className="wrapper wrapper--gray-gb">
      <div className={cn('categories-block')}>
        <div className={cn('categories-block__header')}>
          <h3>{t('pages.categories.name')}</h3>
          <div>
            <Switch
              name="NSFW"
              label="NSFW"
              onChange={({ target: { checked } }) => setNsfc(checked)}
            />
            <ViewAllButton />
          </div>
        </div>
        <CategoriesList nsfc={nsfc} />
        <ViewAllButton />
      </div>
    </div>
  );
};

export default BrowseByBlock;
