import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type Options<DataT> = {
  whenFetch?: (dataInState: DataT | null) => boolean;
};

type FetchState<DataT> = {
  data: DataT | null;
  isLoading: boolean;
};

type FetchActions<DataT, ArgsT = void> = {
  fetch: (args: ArgsT) => Promise<DataT>;
};

export type FetchStore<DataT, ArgsT = void> = FetchState<DataT> &
  FetchActions<DataT, ArgsT>;

const createFetchStore = <DataT, ArgsT = void>(
  apiCall: (args: ArgsT) => Promise<DataT>
) => {
  const useFetchStore = create<FetchStore<DataT, ArgsT>>()(
    immer((set) => ({
      data: null,
      isLoading: false,
      fetch: async (args) => {
        set(() => ({ isLoading: true }));

        const res = await apiCall(args);

        set(() => ({ isLoading: false }));

        if (res !== null) {
          set(() => ({ data: res }));
        }

        return res;
      },
    }))
  );

  const useFetchData = (args: ArgsT, { whenFetch }: Options<DataT> = {}) => {
    const data = useFetchStore((state) => state.data);
    const isLoading = useFetchStore((state) => state.isLoading);

    const when = useCallback(
      (data: DataT | null) => {
        if (whenFetch) {
          return whenFetch(data);
        } else {
          return data === null;
        }
      },
      [whenFetch]
    );

    useEffect(() => {
      if (when(data)) {
        useFetchStore.getState().fetch(args);
      }
    }, []);

    return { data, isLoading };
  };

  return { useFetchStore, useFetchData };
};

export default createFetchStore;
