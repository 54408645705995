import { ApiResponse } from './../types';
import {
  CreatorSearchQueryParams,
  Creator,
  Paginate,
  DetailedCreator,
  PaginationParams,
  CreatorSort,
} from 'models';

import http from '../http';

export type GetCreatorsReturn = Paginate<Creator> | null;
export type GetCreatorsParams = CreatorSearchQueryParams;
export type GetTrendsReturn = string[] | null;
export type GetDetailedCreatorReturn = ApiResponse<DetailedCreator>;
export type GetFavoritesParams = PaginationParams;
export type GetFavoritesReturn = GetCreatorsReturn;
export type AddToFavoritesReturn = Creator | null;

class CreatorsApi {
  getList = async (params: GetCreatorsParams): Promise<GetCreatorsReturn> =>
    http.deprecatedFetch((axios, signal) => {
      if (params.sort === CreatorSort.reviews) {
        return axios.get('/creators/most-reviews', {
          signal,
          params: {
            limit: params.limit ?? 10,
            offset: params.offset ?? 0,
          } as GetCreatorsParams,
        });
      }

      if (params.sort === CreatorSort.verified) {
        return axios.get('/creators/recent-verified', {
          signal,
          params: {
            limit: params.limit ?? 10,
            offset: params.offset ?? 0,
          } as GetCreatorsParams,
        });
      }

      return axios.get('/creators', {
        signal,
        params: {
          limit: 10,
          offset: 0,
          sort: CreatorSort.relevant,
          ...params,
        } as GetCreatorsParams,
      });
    });
  getOne = async (
    creatorUsername: string,
    showError: boolean = true
  ): Promise<GetDetailedCreatorReturn> =>
    http.fetch(
      (axios, signal) =>
        axios.get(`/creators/details/${creatorUsername}`, {
          signal,
        }),
      showError
    );
  trends = async (): Promise<GetTrendsReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.get('/creators/trends', {
        signal,
      })
    );
  favorites = async (params?: PaginationParams): Promise<GetFavoritesReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.get('/favorites/my', {
        signal,
        params: { limit: 10, offset: 0, ...params } as PaginationParams,
      })
    );
  addToFavorites = async (creatorId: string): Promise<AddToFavoritesReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post(
        '/favorites',
        { creatorId },
        {
          signal,
        }
      )
    );
  removeFromFavorites = async (creatorId: string): Promise<object | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.delete(`/favorites/${creatorId}`, {
        signal,
      })
    );
}

export const creatorsApi = new CreatorsApi();
