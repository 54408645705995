import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { useQueryString } from 'hooks';
import { CreatorSearchQueryParams } from 'models';
import { Search } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const Header: FC = () => {
  const { t } = useTranslation();
  const { params, updateParams } = useQueryString<CreatorSearchQueryParams>();
  const { category, search, platform } = params;
  let text = '';

  if (search) {
    text += search;
  }

  if (platform) {
    if (text === '') {
      text += platform;
    } else {
      text += ' ' + platform;
    }
  }

  let title = t('pages.category.filters.topCreators', {
    text: text,
  });

  if (category) {
    title = category;
  }

  if (!category && !search && !platform) {
    title = t('common.all');
  }

  return (
    <div className="wrapper wrapper--black-bg wrapper--with-spots">
      <div className={cn('header')}>
        <h1>{title}</h1>
        <Search
          className={cn('header__search')}
          withNsfw={false}
          defaultValue={search}
          onSubmit={({ search }) =>
            updateParams({ search, category: undefined })
          }
        />
      </div>
    </div>
  );
};

export default Header;
