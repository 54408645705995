import React, { FC, InputHTMLAttributes } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import { Option } from 'types';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type DropdownProps = InputHTMLAttributes<HTMLSelectElement> & {
  className?: string;
  options: Option[];
};

const Dropdown: FC<DropdownProps> = ({ className, options, ...rest }) => {
  return (
    <select {...rest} className={classNames(cn('dropdown'), className)}>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label || value}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
