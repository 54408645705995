const locale = {
  translation: {
    common: {
      any: 'Any',
      na: 'N/A',
      optionalSelect: 'Optional - Select all that apply',
      yes: 'Yes',
      no: 'No',
      and: 'and',
      all: 'All',
      free: 'Free',
    },
    inputs: {
      email: 'Email address',
      password: 'Password',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      username: 'Username',
      about: 'About',
      name: 'Name',
      bio: 'Bio',
      avatar: 'Avatar',
    },
    actions: {
      login: 'Login',
      signOut: 'Sign Out',
      signUp: 'Sign Up',
      viewAll: 'View All',
      apply: 'Apply',
      verify: 'Verify',
      getVerified: 'Get Verified',
      copyToClipboard: 'Copy to Clipboard',
      tryAgain: 'Try Again',
      saveChanges: 'Save Changes',
      changePassword: 'Change Password',
      cancel: 'Cancel',
      edit: 'Edit',
    },
    notify: {
      changePassword: 'The password has been successfully changed',
      updateMyProfile: 'Your profile has been successfully updated',
      sendResetPasswordLink:
        'The password reset link has been sent successfully',
    },
    thirdPartyServices: {
      google: 'Google',
      facebook: 'Facebook',
    },
    noData: {
      common: 'No data',
      search: "We couldn't find any results matching your search",
      reviews:
        "Be the first to share your thoughts about this creator! They haven't received any reviews yet.",
      reviewsForOthers: "Looks like you haven't reviewed any creators yet!",
      reviewsLeftForMe:
        'It seems no one has reviewed your account yet. Encourage others to share their thoughts!',
      favorites:
        "Looks like you haven't added any creators to your favorites yet!",
    },
    components: {
      header: {
        menu: {
          categories: '$t(pages.categories.name)',
          about: '$t(pages.about.name)',
          forCreators: '$t(pages.forCreators.name)',
          reviewsForOthers: '$t(pages.reviewsForOthers.name)',
          reviewsLeftForMe: '$t(pages.reviewsLeftForMe.name)',
          favorites: '$t(pages.myFavorites.name)',
          account: '$t(pages.account.name)',
        },
      },
      footer: {
        menu: {
          privacy: '$t(pages.privacy.name)',
          terms: '$t(pages.terms.name)',
          about: '$t(pages.about.name)',
        },
        copyrightText:
          '© 2024 All rights reserved. Fanseeker. All external content remains the property of the rightful owner.',
      },
      review: {
        reviewed: 'reviewed',
        helpful: 'Helpful',
        yourReply: 'Your reply',
        yourReplyToReview: 'Your reply to the review',
        reply: 'Reply',
      },
      search: {
        name: 'Search',
        shortPlaceholder: 'Search...',
        placeholder: 'Search by name, tag, location, and more...',
      },
      creatorCard: {
        likes: 'likes',
        off: 'Off',
      },
      paginate: {
        next: 'Next',
        previous: 'Previous',
      },
      leaveReview: {
        overalRating: 'Overal Rating',
        expGoodTitle: 'What made the experience good?',
        expBadTitle: 'What made the experience bad?',
        overallExpTitle: 'What was your overall experience?',
        subscriberTitle: 'Are you a current subscriber?',
        anonymousTitle: 'Leave as anonymous?',
        yourComment: 'Your comment',
      },
      authLayout: {
        community: 'Join the 45,000+ Strong Community at Fanseeker',
        title:
          'Become a <b>Fanseeker Member:</b> Share Your Experiences with Creators',
        subtitle:
          'Sign Up for Free and Start Reviewing, Discovering, and Supporting Your Favorite Creators Today',
      },
    },
    pages: {
      home: {
        name: 'Home',
        exploreBlock: {
          title: 'Explore <b>OnlyFans, Patreon</b>, and more!',
          subtitle:
            'Discover and review the best content creators with Fanseeker',
          trending: 'Trending',
        },
        browseByBlock: {
          title: 'Browse By Platform',
        },
        topCreatorsOn: '🏆 Top Creators on ',
        mostReviewsThisMonth: 'Most reviews this month',
        recentlyVerifiedCreators: 'Recently verified creators',
        recentReviews: 'Recent Reviews',
        creatorBlock: {
          title: 'Are you a creator?',
          subtitle:
            'Join Fanseeker as a verified creator and claim your profile page for free.',
          action: 'Claim Your Page',
        },
      },
      categories: {
        name: 'Categories',
        browseCategories: 'Browse <b>Categories</b>',
      },
      forCreators: {
        name: 'For Creators',
        title:
          'Join Fanseeker as a <b>verified creator</b> and claim your profile page for free',
        subtitle:
          "Once you've signed up to Fanseeker and applied for verification on your profiles, you gain the ability to reply to reviews, edit your profile pages, link multiple profiles together, and access analytics.",
      },
      creator: {
        reviews: 'Reviews ({{total}})',
        fromReviews: 'From {{total}} Reviews',
        actions: {
          addToFavorites: 'Add to Favorites',
          removeFromFavorites: 'Added to Favorites',
          view: 'View {{platform}}',
          addTag: 'Add Tag',
          leaveReview: 'Leave a review',
        },
        statistics: {
          likes: 'Likes',
          photos: 'Photos',
          posts: 'Posts',
          videos: 'Videos',
          fans: 'Fans',
        },
        reviewsSortBy: {
          mostHelpful: 'Most helpful',
          mostRecent: 'Most recent',
          mostPositive: 'Most positive',
          mostNegative: 'Most negative',
        },
      },
      category: {
        results: '{{from}}-{{to}} of {{total}} results',
        sortBy: {
          mostRelevant: 'Most relevant',
          mostLiked: 'Most liked',
          mostRated: 'Most rated',
          mostSubscribers: 'Most subscribers',
        },
        filters: {
          title: 'Filters',
          platforms: 'Platforms',
          rating: 'Rating',
          price: 'Price',
          prices: {
            freeOnly: 'Free only',
            fiveAndUnder: '$5 and under',
            fiveToFifteen: '$5 to $15',
            fifteenAndOver: '$15 and over',
          },
          location: 'Location',
          useMyCurrentLocation: 'Use my Current Location',
          gender: 'Gender',
          genders: {
            all: 'All',
            female: 'Female',
            male: 'Male',
            unknown: 'Unknown',
          },
          topCreators: 'Top {{text}} creators',
          relatedSearches: 'Related Searches',
        },
      },
      about: {
        name: 'About',
        welcomeBlock: {
          title: 'Welcome to \n <b>Fanseeker!</b>',
          subtitle:
            'Fanseeker is your gateway to connecting with and supporting the world of content creators, offering a unique platform that spans across various subscription-based services like OnlyFans, Patreon, and beyond.',
        },
        gapBetweenBlock: {
          title:
            'Bridging the Gap Between <b>Content Creators</b> and <b>Fans</b>',
          subtitle:
            'Conceived by a team deeply rooted in content creation and fandom, Fanseeker recognizes the hurdles both fans and creators face in the vast sea of subscription content. Our platform is designed to close the gap between content creators and their audience, simplifying the discovery process for fans, and enabling creators to expand their reach and increase their earnings.',
        },
        whatWeOfferBlock: {
          title: 'What We Offer',
          forFans: 'For Fans',
          forCreators: 'For Creators',
          exploreAndConnect: {
            title: 'Explore and Connect',
            text: 'With Fanseeker’s advanced search and filtering capabilities, it’s easier than ever to find creators who align with your interests, preferences, and location.',
          },
          insightsFromUserReviews: {
            title: 'Insights from User Reviews',
            text: 'Leverage our community-driven reviews and ratings to choose the creators you want to support, guided by insights from like-minded subscribers.',
          },
          stayUpdated: {
            title: 'Stay Updated',
            text: 'Remain at the forefront of subscription-based content with our curated news, updates, and trend reports.',
          },
          boostYourVisibility: {
            title: 'Boost Your Visibility',
            text: 'Fanseeker shines a spotlight on your work, helping you to connect with a broader audience of potential supporters. Highlight your positive reviews to attract new fans!',
          },
          receiveConstructiveFeedback: {
            title: 'Receive Constructive Feedback',
            text: 'Benefit from the community’s reviews and ratings to gauge what resonates with your audience and how to enhance your offerings.',
          },
          cultivateYourCommunity: {
            title: 'Cultivate Your Community',
            text: 'Fanseeker encourages a strong connection between you and your supporters, fostering a supportive and engaged community.',
          },
        },
        coreValuesBlock: {
          title: 'Our Core Values',
          text: `Integrity: Our commitment to honesty, transparency, and unbiased information ensures our users can make informed choices confidently.
          <br /><br />
          Inclusivity: We champion diversity and aim to create a welcoming space for creators and fans of all backgrounds, proudly promoting creators of all types, including adult content.
          <br /><br />
          Innovation: Dedicated to staying at the forefront of subscription content, we constantly evolve and adapt to serve our community better.
          <br /><br />
          Empowerment: Our mission is to empower both fans and creators with the tools and knowledge needed for success in the subscription content realm.`,
        },
        privacyBlock: {
          title: '<b>Protecting</b> Creators and Privacy',
          text: `Fanseeker staunchly upholds the privacy and intellectual property rights of creators. We stand firmly against any form of content leaks or unauthorized distribution. Our platform displays information sourced from publicly accessible data, and we urge our community to support creators through subscriptions for exclusive content.
          <br /><br />
          Should a creator wish to opt-out from our platform, we respect such decisions. Creators can reach out to us at <a href="mailto:support@fanseeker.com">support@fanseeker.com</a> for profile removal requests, and we will address these promptly.
          <br /><br />
          Our dedication to creator support and rights protection is at the heart of what we do at Fanseeker, striving for a platform that is ethical and considerate.`,
        },
        communityBlock: {
          title: 'Join Our Community',
          text: `Dive into the expansive universe of subscription-based content with Fanseeker. Whether you’re a fan in search of the ideal creator to support or a creator looking to widen your audience and boost your income, Fanseeker is your partner every step of the way.
          <br /><br />
          We’re eager to hear from you - for any inquiries, feedback, or suggestions, feel free to reach out. We’re here to assist and listen.`,
        },
      },
      myFavorites: {
        name: 'Favorites',
        title: 'My <b>Fanseeker</b> Favorites ❤️',
      },
      reviewsForOthers: {
        name: 'Reviews For Others',
        title: 'Reviews <b>For Others</b> ⭐',
      },
      reviewsLeftForMe: {
        name: 'Reviews Left For Me',
        title: 'Reviews <b>Left For Me</b> ⭐',
      },
      resetPassword: {
        title: 'Set New Password',
        newPassword: 'New Password',
        repeatPassword: 'Repeat Password',
        action: 'Set Password',
      },
      forgotPassword: {
        title: 'Forgot Password?',
        subtitle:
          "No worries! Simply provide your email, and we'll send you a link to reset your password.",
        action: 'Send Reset Link',
      },
      auth: {
        dontHaveAccount: 'Don’t have an account?',
        haveAccount: 'Already have an account?',
        termsText:
          'By continuing, you agree that we create a Fanseeker’s account for you, and accept our',
      },
      account: {
        name: 'My Account',
        mySettings: 'My Settings',
      },
      verification: {
        step1: 'Step 1: What profile would you like to verify for?',
        step2:
          'Step 2: Go to your <a href="https://onlyfans.com/" target="_blank">Onlyfans</a> or <a href="https://www.patreon.com/" target="_blank">Patreon</a> and go to your settings to edit your bio.',
        step3: 'Step 3: Add the following text anywhere in your bio.',
        step4:
          'Step 4: Save your bio on OnlyFans or Patreon and come back here and press verify.',
        checkoutReview: 'Check out my reviews on Fanseeker: {{link}}',
        success: {
          title: 'Verification Complete! 🌟',
          subtitle:
            "Congratulations, your account has been successfully linked to your Fanseeker profile. You're now verified! Welcome aboard!",
        },
        error: {
          title: 'Oops! Verification Failed. 😕',
          subtitle: `We couldn't detect the link in your OnlyFans/Patreon/Twitch. Please make sure you've copied the exact link we sent you to your OnlyFans/Patreon/Twitch and try verifying again.
Our team has been informed about this issue and will be investigating it as soon as possible.`,
        },
        pending: 'Please wait for verification results',
      },
      privacy: {
        name: 'Privacy Policy',
      },
      terms: {
        name: 'Terms',
        fullName: 'Terms and Conditions',
      },
    },
    errors: {
      api: {
        notAuthorized: 'Not authorized',
        invalidCredentials: 'Username or password is not correct',
        other: 'Something went wrong',
        notFound: 'Not found',
      },
      validation: {
        repeatPassword: 'Your passwords do not match',
      },
    },
  },
};

export default locale;
