import React, { Fragment } from 'react';

import {
  ExploreBlock,
  BrowseByBlock,
  CategoriesBlock,
  TopCreatorsOnlyFans,
  TopCreatorsPatreon,
  TopCreatorsTwitch,
  RecentlyVerifiedCreators,
  MostReviewsThisMonth,
  RecentReviews,
  CreatorBlock,
} from './components';

const Home = () => {
  return (
    <Fragment>
      <ExploreBlock />
      <BrowseByBlock />
      <CategoriesBlock />
      <TopCreatorsOnlyFans />
      <TopCreatorsPatreon />
      <TopCreatorsTwitch />
      <RecentlyVerifiedCreators />
      <MostReviewsThisMonth />
      <RecentReviews />
      <CreatorBlock />
    </Fragment>
  );
};

export default Home;
