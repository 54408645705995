import React, { FC, Fragment, InputHTMLAttributes } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type DividerProps = InputHTMLAttributes<HTMLDivElement> & {
  className?: string;
  text?: string;
};

const Divider: FC<DividerProps> = ({ className, text, ...rest }) => {
  return (
    <div {...rest} className={classNames(cn('divider'), className)}>
      <hr />
      {text && (
        <Fragment>
          <p>or</p>
          <hr />
        </Fragment>
      )}
    </div>
  );
};

export default Divider;
