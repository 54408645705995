import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';

import { ParsedHtml, Price } from 'components/uikit';
import { DetailedCreator } from 'models';
import { useProfileStore } from 'stores';

import Statistic from '../Statistic';
import FavoriteAndViewButtons, {
  FavoriteAndViewButtonsProps,
} from '../FavoriteAndViewButtons';
import CreatorLinks from '../CreatorLinks';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type RightCardProps = Pick<FavoriteAndViewButtonsProps, 'toggleFavorite'> & {
  data: DetailedCreator;
};

const RightCard: FC<RightCardProps> = ({ data, toggleFavorite }) => {
  const {
    id,
    promoOff,
    price,
    promoPrice,
    platform,
    bio,
    counters,
    url,
    favorite,
    links,
  } = data;
  const isFavorite = favorite || false;
  const accountId = useProfileStore((state) => state.profile?.id);
  const isMyAccount = accountId?.toString() === id;

  const Buttons = () =>
    isMyAccount ? (
      <div />
    ) : (
      <FavoriteAndViewButtons
        toggleFavorite={toggleFavorite}
        isFavorite={isFavorite}
        platform={platform}
        url={url}
      />
    );

  return (
    <div className={cn('right-card')}>
      <div>
        <div className={cn('right-card__header')}>
          <Price
            promoOff={promoOff}
            priceInCents={price}
            promoPriceInCents={promoPrice}
          />
          <Buttons />
        </div>
        {bio && <ParsedHtml tag="p">{bio}</ParsedHtml>}
        {/* <div className={cn('right-card__user-tags')}>
          {userTags.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
          <button>{t('pages.creator.actions.addTag')}</button>
        </div> */}
        {links && <CreatorLinks links={links} />}
      </div>
      {counters && (
        <Statistic className={cn('right-card__stats')} counters={counters} />
      )}
    </div>
  );
};

export default RightCard;
