import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { TextInput } from 'components/form';
import { CircularIndicator } from 'components/uikit';
import { useProfileStore } from 'stores';

const schema = yup.object({
  'current-password': yup.string().min(8).max(20).required(),
  'new-password': yup.string().min(8).max(20).required(),
});

type FormData = yup.InferType<typeof schema>;

const ChangePasswordForm: FC = () => {
  const { t } = useTranslation();
  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async (values) => {
    await useProfileStore.getState().changePassword({
      oldPassword: values['current-password'],
      newPassword: values['new-password'],
    });
    reset({ 'current-password': '', 'new-password': '' });
  });

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        name="current-password"
        control={control}
        componentProps={{
          type: 'password',
          label: t('inputs.currentPassword'),
        }}
      />
      <TextInput
        name="new-password"
        control={control}
        componentProps={{
          type: 'password',
          label: t('inputs.newPassword'),
        }}
      />
      <button
        type="submit"
        disabled={isButtonDisabled}
        className="button--primary"
      >
        {t('actions.changePassword')}
        {isSubmitting && <CircularIndicator />}
      </button>
    </form>
  );
};

export default ChangePasswordForm;
