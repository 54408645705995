import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { Rating, RatingProps } from '../uikit';
import { DefaultInputProps } from './types';

const RatingInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultInputProps<FormValues, RatingProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange } = field;

  return (
    <div>
      <Rating value={value} onChange={onChange} {...componentProps} />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default RatingInput;
