import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PATHS } from 'router';

const VerificationError = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h5>{t('pages.verification.error.title')}</h5>
      <p>{t('pages.verification.error.subtitle')}</p>
      <Link className="button--primary" to={PATHS.verification}>
        {t('actions.tryAgain')}
      </Link>
    </Fragment>
  );
};

export default VerificationError;
