import React, { Fragment, FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthStore, useProfileStore } from 'stores';

import PATHS from './paths';

type ProtectedRoute = {
  from: 'authUser' | 'notAuthUser' | 'notCreator';
};

const ProtectedRoute: FC<PropsWithChildren<ProtectedRoute>> = ({
  children,
  from,
}) => {
  const isAuth = useAuthStore((state) => state.accessToken !== null);
  const isCreator = useProfileStore((state) => !!state.profile?.creator?.id);
  const souldRedirect =
    (from === 'authUser' && isAuth) ||
    (from === 'notAuthUser' && !isAuth) ||
    (from === 'notCreator' && !isCreator);

  if (souldRedirect) {
    let redirectPath;

    switch (from) {
      case 'authUser':
        redirectPath = '/';
        break;
      case 'notAuthUser':
        redirectPath = PATHS.signIn;
        break;
      case 'notCreator':
        redirectPath = isAuth ? '/' : PATHS.signIn;
        break;
    }

    return <Navigate to={redirectPath} />;
  }

  return <Fragment>{children}</Fragment>;
};

export default ProtectedRoute;
