import React, { FC, PropsWithChildren, Children } from 'react';
import Slider, { Settings } from 'react-slick';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

import { BREAKPOINTS } from 'styles/vars';
import { useWindowDimensions } from 'hooks';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type SliderProps = Settings & {
  className?: string;
  isLoading?: boolean;
  skeletonProps?: SkeletonProps;
};

const AppSlider: FC<PropsWithChildren<SliderProps>> = ({
  className,
  children,
  isLoading,
  skeletonProps,
  ...sliderSettings
}) => {
  const { width } = useWindowDimensions();
  const settings: Settings = {
    dots: true,
    speed: 500,
    slidesToShow: 7.7,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 3200,
        settings: {
          slidesToShow: 7.7,
        },
      },
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 6.7,
        },
      },
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5.7,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4.7,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3.7,
        },
      },
      {
        breakpoint: BREAKPOINTS.L,
        settings: {
          slidesToShow: 2.7,
        },
      },
      {
        breakpoint: BREAKPOINTS.M,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: BREAKPOINTS.S,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    ...sliderSettings,
  };

  const slidesLength = Children.toArray(children).length ?? 1;
  const responsive = settings.responsive ?? [];
  const currentBreakpoint = responsive
    .filter(({ breakpoint }) => breakpoint >= width)
    .sort((a, b) => a.breakpoint - b.breakpoint)[0];
  const slidesToShow = currentBreakpoint
    ? currentBreakpoint.settings === 'unslick'
      ? 1
      : currentBreakpoint.settings.slidesToShow ?? 1
    : settings.slidesToShow ?? 1;

  if (slidesLength <= slidesToShow) {
    settings.infinite = false;
    settings.draggable = false;
  }

  return (
    <div className={classNames(cn('slider'), className)}>
      <Slider {...settings}>
        {isLoading
          ? [...Array(6).keys()].map((item) => (
              <Skeleton key={item} {...skeletonProps} />
            ))
          : children}
      </Slider>
    </div>
  );
};

export default AppSlider;
