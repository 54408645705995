import React, { Dispatch, SetStateAction, FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import { RatingInput, ToggleButtonGroup, TextArea } from 'components/form';
import { CircularIndicator } from 'components/uikit';
import { useOutsideTap } from 'hooks';

import useLeaveReviewForm from './useLeaveReviewForm';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type LeaveReviewFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const LeaveReviewForm: FC<LeaveReviewFormProps> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const ref = useOutsideTap<HTMLFormElement>(true, () => setIsOpen(false));
  const { control, onSubmit, isButtonDisabled, isSubmitting } =
    useLeaveReviewForm({
      setIsOpen,
    });

  return (
    <div className={cn('leave-review-dialog')}>
      <form onSubmit={onSubmit} ref={ref}>
        <div className={cn('leave-review-dialog__header')}>
          <h5>{t('pages.creator.actions.leaveReview')}</h5>
          <CgClose size={32} onClick={() => setIsOpen(false)} />
        </div>
        <div className={cn('leave-review-dialog__rating')}>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.overalRating')}
          </p>
          <RatingInput name="rating" control={control} />
        </div>
        <div>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.expGoodTitle')}
            <br />
            <b>{t('common.optionalSelect')}</b>
          </p>
          <ToggleButtonGroup
            name="positive"
            control={control}
            items={[
              { value: 'Quality Content' },
              { value: 'Responsiveness' },
              { value: 'Live Shows' },
              { value: 'Fair prices' },
              { value: 'Free Content' },
              { value: '1-on-1 Calls' },
              { value: 'Custom content' },
            ]}
          />
        </div>
        <div>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.expBadTitle')}
            <br />
            <b>{t('common.optionalSelect')}</b>
          </p>
          <ToggleButtonGroup
            name="negative"
            control={control}
            items={[
              { value: 'Too Spammy' },
              { value: 'Poor content' },
              { value: 'Slow to reply' },
              { value: 'Impersonal' },
              { value: 'Rude' },
              { value: 'Too many ads' },
              { value: 'Lack of updates' },
            ]}
          />
        </div>
        <div>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.overallExpTitle')}
          </p>
          <TextArea
            name="comment"
            control={control}
            componentProps={{
              placeholder: t('components.leaveReview.yourComment'),
            }}
          />
        </div>
        <div>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.subscriberTitle')}
          </p>
          <ToggleButtonGroup
            name="isSubscriber"
            control={control}
            componentProps={{
              type: 'radio',
            }}
            items={[
              { label: t('common.yes'), value: 'true' },
              { label: t('common.no'), value: 'false' },
            ]}
          />
        </div>
        <div>
          <p className={cn('leave-review-dialog__title')}>
            {t('components.leaveReview.anonymousTitle')}
          </p>
          <ToggleButtonGroup
            name="anonymous"
            control={control}
            componentProps={{
              type: 'radio',
            }}
            items={[
              { label: t('common.yes'), value: 'true' },
              { label: t('common.no'), value: 'false' },
            ]}
          />
        </div>
        <button
          type="submit"
          className="button--primary"
          disabled={isButtonDisabled}
        >
          {t('actions.apply')}
          {isSubmitting && <CircularIndicator />}
        </button>
      </form>
    </div>
  );
};

export default LeaveReviewForm;
