import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { round } from 'utils';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type PriceProps = {
  className?: string;
  priceInCents?: number | null;
  promoOff?: number | null;
  promoPriceInCents?: number | null;
};

const Price: FC<PriceProps> = ({
  className,
  promoOff,
  promoPriceInCents,
  priceInCents,
}) => {
  const { t } = useTranslation();
  const price = round((priceInCents || 0) / 100, 2) || 0;
  const promoPrice = round((promoPriceInCents || 0) / 100, 2) || 0;
  const hasPromo = !!promoOff && promoOff > 0;
  const showOldPrice = hasPromo && price > 0;
  let displayedPrice = '';

  if (hasPromo) {
    displayedPrice = ` $${promoPrice}`;
  } else {
    displayedPrice = price > 0 ? ` $${price}` : ` ${t('common.free')}`;
  }

  return (
    <div className={classNames(cn('price'), className)}>
      {hasPromo && (
        <span>
          <p>
            {round(promoOff)}% {t('components.creatorCard.off')}
          </p>
        </span>
      )}
      <h6>
        {showOldPrice && <b>${price}</b>}
        {displayedPrice}
      </h6>
    </div>
  );
};

export default Price;
