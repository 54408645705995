import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useFetchFavorites, useFavoritesStore } from 'stores';
import { CreatorCard, Paginate } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type FavoritesListProps = ReturnType<typeof useFetchFavorites>;

const FavoritesList: FC<FavoritesListProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={cn('favorites__list')}>
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} height={500} borderRadius={12} />
        ))}
      </div>
    );
  }

  if (data === null || data.count === 0) {
    return <h5>{t('noData.favorites')}</h5>;
  }

  return (
    <div className={cn('favorites__list')}>
      {data?.results?.map(
        ({
          id,
          price,
          media,
          name,
          platform,
          bio,
          rating,
          promoPrice,
          promoOff,
          username,
          counters,
        }) => (
          <CreatorCard
            key={id}
            priceInCents={price}
            promoPriceInCents={promoPrice}
            promoOff={promoOff}
            imageSrc={media?.avatar || null}
            platform={platform}
            likes={counters?.likes || null}
            name={name}
            username={username}
            bio={bio}
            rating={rating}
          />
        )
      )}
    </div>
  );
};

const PaginatedFavoritesList = () => {
  const { data, isLoading } = useFetchFavorites();

  const handlePageClick = ({ selected }: { selected: number }) => {
    const store = useFavoritesStore.getState();
    const limit = store.favorites?.limit || 0;
    const offset = limit * selected;

    store.fetch({ offset }, { showLoader: false });
  };

  return (
    <div>
      <FavoritesList data={data} isLoading={isLoading} />
      <Paginate onPageChange={handlePageClick} itemsCount={data?.count || 0} />
    </div>
  );
};

export default PaginatedFavoritesList;
