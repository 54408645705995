import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useCreatorStore from '../../store';

const schema = yup.object({
  comment: yup.string().min(3).max(1000).required(),
  rating: yup.number().min(1).max(5).required(),
  positive: yup.array().of(yup.string().required()).optional(),
  negative: yup.array().of(yup.string().required()).optional(),
  isSubscriber: yup.boolean(),
  anonymous: yup.boolean(),
});

type FormData = yup.InferType<typeof schema>;

const useLeaveReviewForm = ({
  setIsOpen,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const isButtonDisabled = !isValid || isSubmitting;
  const onSubmit = handleSubmit(async (values) => {
    const store = useCreatorStore.getState();
    const creatorId = store.creator?.id;

    if (!creatorId) return;

    await store.createReview({
      creatorId,
      ...values,
    });

    setIsOpen(false);
  });

  return { control, onSubmit, isButtonDisabled, isSubmitting };
};

export default useLeaveReviewForm;
