import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Verification } from 'modules';

import PATHS from '../paths';
import ProtectedRoute from '../ProtectedRoute';

const verificationRoute: RouteObject = {
  path: PATHS.verification,
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute from="notAuthUser">
          <Verification show="form" />
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.verificationSuccessKey,
      element: <Verification show="success" />,
    },
    {
      path: PATHS.verificationErrorKey,
      element: <Verification show="error" />,
    },
  ],
};

export default verificationRoute;
