import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const WhatWeOfferBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper--gray-bg wrapper--with-spots">
      <div className={cn('offer-block')}>
        <h2>{t('pages.about.whatWeOfferBlock.title')}</h2>
        <h5>{t('pages.about.whatWeOfferBlock.forFans')}</h5>
        <div className={cn('offer-block__cards-wrapper')}>
          <div className={cn('offer-block__card')}>
            <h5>{t('pages.about.whatWeOfferBlock.exploreAndConnect.title')}</h5>
            <p>{t('pages.about.whatWeOfferBlock.exploreAndConnect.text')}</p>
          </div>
          <div className={cn('offer-block__card')}>
            <h5>
              {t('pages.about.whatWeOfferBlock.insightsFromUserReviews.title')}
            </h5>
            <p>
              {t('pages.about.whatWeOfferBlock.insightsFromUserReviews.text')}
            </p>
          </div>
          <div className={cn('offer-block__card')}>
            <h5>{t('pages.about.whatWeOfferBlock.stayUpdated.title')}</h5>
            <p>{t('pages.about.whatWeOfferBlock.stayUpdated.text')}</p>
          </div>
        </div>
        <h5>{t('pages.about.whatWeOfferBlock.forCreators')}</h5>
        <div className={cn('offer-block__cards-wrapper')}>
          <div className={cn('offer-block__card')}>
            <h5>
              {t('pages.about.whatWeOfferBlock.boostYourVisibility.title')}
            </h5>
            <p>{t('pages.about.whatWeOfferBlock.boostYourVisibility.text')}</p>
          </div>
          <div className={cn('offer-block__card')}>
            <h5>
              {t(
                'pages.about.whatWeOfferBlock.receiveConstructiveFeedback.title'
              )}
            </h5>
            <p>
              {t(
                'pages.about.whatWeOfferBlock.receiveConstructiveFeedback.text'
              )}
            </p>
          </div>
          <div className={cn('offer-block__card')}>
            <h5>
              {t('pages.about.whatWeOfferBlock.cultivateYourCommunity.title')}
            </h5>
            <p>
              {t('pages.about.whatWeOfferBlock.cultivateYourCommunity.text')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOfferBlock;
