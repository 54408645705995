/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import storage from 'services/storage';

import resources from './resources';

const restoreLanguage = async (): Promise<void> => {
  const lng = await storage.getItem(storage.LANGUAGE_KEY);
  if (lng) {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  }
};

const persistLanguage = (lng: string): void => {
  storage.setItem(storage.LANGUAGE_KEY, lng);
};

i18n.use(initReactI18next).init(
  {
    lng: 'en',
    resources: resources,
    react: {
      useSuspense: true,
    },
    returnNull: false,
  },
  restoreLanguage
);
i18n.on('languageChanged', persistLanguage);

export default i18n;
