import { useEffect, useCallback } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  creatorsApi,
  reviewsApi,
  GetDetailedCreatorReturn,
  GetReviewsReturn,
  GetCreatorReviewsParams,
} from 'api';
import { CreateReviewBody, DetailedCreator } from 'models';
import { useFavoritesStore, useMyReviewsStore } from 'stores';
import { FetchOptions } from 'types';
import { PATHS } from 'router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CreatorState = {
  isReviewsFetching: boolean;
  isCreatorFetching: boolean;
  reviews: GetReviewsReturn | null;
  creator: DetailedCreator | null;
};

type CreatorActions = {
  setFavorite: (value: boolean) => void;
  addToFavorites: () => Promise<void>;
  removeFromFavorites: () => Promise<void>;
  toggleFavorite: () => Promise<void>;
  fetchCreator: (
    args: string,
    options?: FetchOptions
  ) => Promise<GetDetailedCreatorReturn>;
  fetchReviews: (
    args: GetCreatorReviewsParams,
    options?: FetchOptions
  ) => Promise<GetReviewsReturn>;
  createReview: (body: CreateReviewBody) => Promise<void>;
  toogleHelpful: (reviewId: string) => Promise<void>;
  setHelpful: (index: number, value: boolean) => void;
};

export type CreatorStore = CreatorState & CreatorActions;

const useCreatorStore = create<CreatorStore>()(
  immer((set, get) => ({
    creator: null,
    reviews: null,
    isReviewsFetching: false,
    isCreatorFetching: false,

    setFavorite: (value) =>
      set((state) => {
        if (state.creator?.favorite !== undefined) {
          state.creator.favorite = value;
        }
      }),

    setHelpful: (index, value) =>
      set((prev) => {
        if (prev.reviews !== null && prev.reviews.data !== null) {
          prev.reviews.data.results[index].helpful = value;
        }
      }),

    addToFavorites: async () => {
      const { setFavorite, creator } = get();

      setFavorite(true);

      const success = await useFavoritesStore
        .getState()
        .addToFavorites(creator?.id || '');

      if (!success) {
        setFavorite(false);
      }
    },

    removeFromFavorites: async () => {
      const { setFavorite, creator } = get();

      setFavorite(false);

      const success = await useFavoritesStore
        .getState()
        .removeFromFavorites(creator?.id || '');

      if (!success) {
        setFavorite(true);
      }
    },

    toggleFavorite: async () => {
      const { creator, addToFavorites, removeFromFavorites } = get();

      return creator?.favorite ? removeFromFavorites() : addToFavorites();
    },

    fetchReviews: async (
      params,
      { showLoader = true, showError = true } = {}
    ) => {
      if (showLoader) {
        set(() => ({ isReviewsFetching: true }));
      }

      const res = await reviewsApi.creatorReviews(params, showError);

      if (showLoader) {
        set(() => ({ isReviewsFetching: false }));
      }

      if (res !== null) {
        set((state) => {
          state.reviews = res;
        });
      }

      return res;
    },

    fetchCreator: async (
      username: string,
      { showLoader = true, showError = true } = {}
    ) => {
      if (showLoader) {
        set(() => ({ isCreatorFetching: true }));
      }

      const res = await creatorsApi.getOne(username, showError);
      const { data } = res;

      if (showLoader) {
        set(() => ({ isCreatorFetching: false }));
      }

      if (data !== null) {
        set((state) => {
          state.creator = data;
        });
      }

      return res;
    },

    createReview: async (body) => {
      const success = await useMyReviewsStore.getState().createReview(body);

      if (!success) return;

      const { fetchReviews, fetchCreator, reviews, creator } = get();
      const username = creator?.username || '';
      const limit = reviews?.data?.limit;
      const offset = reviews?.data?.offset;

      await Promise.all([
        fetchReviews({ username, limit, offset }, { showLoader: false }),
        fetchCreator(username, { showLoader: false }),
      ]);
    },

    toogleHelpful: async (reviewId) => {
      const { reviews, setHelpful } = get();
      const data = reviews?.data?.results;

      if (data === undefined) return;

      const index = data.findIndex((el) => el.id === reviewId);

      if (index === -1) return;

      const review = data[index];

      if (!review) return;

      const helpful = review.helpful ?? false;

      setHelpful(index, !helpful);

      const action = helpful ? reviewsApi.unhelpful : reviewsApi.helpful;
      const res = await action(reviewId);

      if (res === null) {
        setHelpful(index, helpful);
      }
    },
  }))
);

export const useFetchDetailedCreator = (username: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useCreatorStore((state) => state.creator);
  const isLoading = useCreatorStore((state) => state.isCreatorFetching);

  const fetch = useCallback(async (username: string) => {
    const res = await useCreatorStore
      .getState()
      .fetchCreator(username, { showError: false });

    if (res.error !== null) {
      const status = res.error.response?.status;

      if (status === 404) {
        const path = PATHS.getError({
          message: t('errors.api.notFound'),
          status,
        });
        navigate(path);
      }
    }
  }, []);

  useEffect(() => {
    if (data?.username !== username) {
      fetch(username);
    }
  }, []);

  return { data, isLoading };
};

export const useFetchCreatorReviews = (username: string) => {
  const data = useCreatorStore((state) => state.reviews?.data);
  const isLoading = useCreatorStore((state) => state.isReviewsFetching);

  useEffect(() => {
    const creatorUsername = useCreatorStore.getState().creator?.username;

    if (creatorUsername !== username) {
      useCreatorStore
        .getState()
        .fetchReviews({ username }, { showError: false });
    }
  }, []);

  return { data, isLoading };
};

export default useCreatorStore;
