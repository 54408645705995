import React, { useEffect, useState, useCallback } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useAuthStore, useCategoriesStore, useProfileStore } from 'stores';
import Splash from 'modules/Splash';
import router from 'router';

const useInitialApp = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchAll = useCallback(async () => {
    const authStore = useAuthStore.getState();

    authStore.setTokenToHeadersFromStore();
    useCategoriesStore.getState().fetch({});

    if (authStore.accessToken) {
      useProfileStore.getState().fetch();
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchAll();
  }, []);

  return isLoading;
};

function App() {
  const isLoading = useInitialApp();

  if (isLoading) {
    return <Splash />;
  }

  return <RouterProvider router={router} />;
}

export default App;
