import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { IoIosStar } from 'react-icons/io';

import { Image } from 'components/uikit';
import { DetailedCreator } from 'models';
import { round } from 'utils';

import Statistic from '../Statistic';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type LeftCardProps = {
  data: DetailedCreator;
};

const LeftCard: FC<LeftCardProps> = ({ data }) => {
  const { name, media, rating, username, counters } = data;
  const roundedRating = round(rating);
  const showRating = roundedRating && roundedRating > 0;

  return (
    <div className={cn('left-card')}>
      <Image src={media?.avatar} className={cn('left-card__image')} />
      <div className={cn('left-card__header')}>
        <div>
          <p>{username || ''}</p>
          <h5>{name || ''}</h5>
        </div>
        {showRating && (
          <span>
            <IoIosStar size={16} color="orange" />
            {roundedRating}
          </span>
        )}
      </div>
      {counters && (
        <Statistic className={cn('left-card__stats')} counters={counters} />
      )}
    </div>
  );
};

export default LeftCard;
