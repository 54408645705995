import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { HighlightedText } from 'components/uikit';

const cn = classNamesBind.bind(styles);

const ProtectingAndPrivacyBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper--with-spots">
      <div className={cn('protecting-block')}>
        <HighlightedText tag="h2">
          {t('pages.about.privacyBlock.title')}
        </HighlightedText>
        <HighlightedText tag="h5">
          {t('pages.about.privacyBlock.text')}
        </HighlightedText>
      </div>
    </div>
  );
};

export default ProtectingAndPrivacyBlock;
