import http from '../http';
import { Category, Platforms } from 'models';

export type GetCategoriesParams = {
  nsfw?: boolean;
  platform?: Platforms;
};
export type GetCategoriesReturn = Category[] | null;

class CategoriesApi {
  getList = async (params: GetCategoriesParams): Promise<GetCategoriesReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.get('/categories', { signal, params })
    );
}

export const categoriesApi = new CategoriesApi();
