import http from '../http';
import { TokensWithProfile, RegisterBody, LoginBody } from 'models';

export type ChangePasswordBody = {
  oldPassword: string;
  newPassword: string;
};

export type ForgotPasswordBody = {
  email: string;
};

export type SetPasswordBody = {
  password: string;
  token: string;
};

class AuthApi {
  register = async (body: RegisterBody): Promise<TokensWithProfile | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/auth/register', body, { signal })
    );

  login = async (body: LoginBody): Promise<TokensWithProfile | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/auth/login', body, { signal })
    );

  refreshTokens = async (): Promise<TokensWithProfile | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/auth/refresh', {}, { signal })
    );

  changePassword = async (body: ChangePasswordBody): Promise<object | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/auth/change-password', body, { signal })
    );

  forgotPassword = async (body: ForgotPasswordBody): Promise<object | null> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/auth/forgot-password', body, { signal })
    );

  setPassword = async ({
    token,
    password,
  }: SetPasswordBody): Promise<object | null> =>
    http.deprecatedFetch(async (axios, signal) => {
      http.setAuthTokenToHeaders(token);

      const res = await axios.post(
        '/auth/set-password',
        { password },
        { signal }
      );

      http.clearAuthTokenFromHeaders();

      return res;
    });
}

export const authApi = new AuthApi();
