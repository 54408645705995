import React, { FC, FormEvent } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { FiSearch } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { Switch } from 'components/uikit';
import { useWindowDimensions } from 'hooks';
import { BREAKPOINTS } from 'styles/vars';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type SearchFormValues = {
  search: string;
  nsfw?: boolean;
};

type SearchProps = {
  className?: string;
  defaultValue?: string;
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (values: SearchFormValues) => void;
  withNsfw?: boolean;
};

const Search: FC<SearchProps> = ({
  className,
  defaultValue,
  withNsfw = true,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = width <= BREAKPOINTS.S;
  const searchName = 'search';
  const switchName = 'nsfw';

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formValues = Object.fromEntries(formData);

    const values: SearchFormValues = {
      search: formValues[searchName].toString(),
      nsfw: formValues[switchName] === 'on',
    };

    onSubmit && onSubmit(values);
  };

  return (
    <form
      className={classNames(className, cn('search-wrapper'))}
      onSubmit={onSubmitHandler}
    >
      <input
        name={searchName}
        defaultValue={defaultValue}
        type="search"
        placeholder={
          isMobile
            ? t('components.search.shortPlaceholder')
            : t('components.search.placeholder')
        }
      />
      {withNsfw && <Switch name={switchName} label="NSFW" />}
      <button type="submit">
        {isMobile ? <FiSearch size={24} /> : t('components.search.name')}
      </button>
    </form>
  );
};

export default Search;
