import http from '../http';
import { DetailedCreator } from 'models';

export type VerifyProfileBody = {
  username: string;
};

export type VerifyProfileReturn = {
  creator: DetailedCreator | null;
  success: boolean | null;
} | null;

class VerificationApi {
  verify = async (body: VerifyProfileBody): Promise<VerifyProfileReturn> =>
    http.deprecatedFetch((axios, signal) =>
      axios.post('/verification', body, { signal })
    );
}

export const verificationApi = new VerificationApi();
