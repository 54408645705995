import React from 'react';
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'router';
import { Review as MReview } from 'models';
import { Review, HighlightedText, Search } from 'components/uikit';
import ThreeWhiteLines from 'assets/3-white-lines.svg';
import BlueStar from 'assets/blue-star.svg';

import styles from './styles.module.scss';
import { useFetchTrends } from './store';

const cn = classNames.bind(styles);

const TrendsList = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useFetchTrends();

  if (isLoading) {
    return (
      <div className={cn('explore-block__trending')}>
        <p>{t('pages.home.exploreBlock.trending')}</p>
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} width={150} height={50} borderRadius={32} />
        ))}
      </div>
    );
  }

  if (data === null || data?.length === 0) {
    return <div />;
  }

  return (
    <div className={cn('explore-block__trending')}>
      <p>{t('pages.home.exploreBlock.trending')}</p>
      {data?.map((item, index) => (
        <Link key={index} to={PATHS.getSearch({ search: item })}>
          {item}
        </Link>
      ))}
    </div>
  );
};

const ExploreBlock = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const review: MReview = {
    id: '0',
    name: 'Jake M.',
    creatorName: 'Kelly',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    helpful: false,
    avatarUrl: null,
    positive: null,
    negative: null,
    reactions: null,
    userId: null,
    rating: 5,
    comment:
      "Kelly is a standout creator! ✨ Kelly's content is thoughtfully curated, featuring a range of durations and themes to suit all preferences.",
  };

  return (
    <div className="wrapper wrapper--black-bg wrapper--with-spots">
      <div className={cn('explore-block')}>
        <div className={cn('explore-block__primary-info')}>
          <HighlightedText tag="h1">
            {t('pages.home.exploreBlock.title')}
          </HighlightedText>
          <h5>{t('pages.home.exploreBlock.subtitle')}</h5>
          <Search onSubmit={(values) => navigate(PATHS.getSearch(values))} />
          <TrendsList />
        </div>
        <div className={cn('explore-block__secondary-info')}>
          <img
            className={cn('explore-block__three-white-lines')}
            src={ThreeWhiteLines}
          />
          <img className={cn('explore-block__blue-star')} src={BlueStar} />
          <Review
            className={cn('explore-block__review')}
            canRate={false}
            review={review}
          />
        </div>
      </div>
    </div>
  );
};

export default ExploreBlock;
