import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CoreValuesImage from 'assets/about-core-image.png';

import styles from './styles.module.scss';
import { HighlightedText } from 'components/uikit';

const cn = classNamesBind.bind(styles);

const CoreFeaturesBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper--black-bg wrapper--with-spots">
      <div className={cn('core-values-block')}>
        <div>
          <img
            src={CoreValuesImage}
            alt={t('pages.about.coreValuesBlock.title')}
          />
        </div>
        <div className={cn('core-values-block__content')}>
          <HighlightedText tag="h2">
            {t('pages.about.coreValuesBlock.title')}
          </HighlightedText>
          <HighlightedText tag="h5">
            {t('pages.about.coreValuesBlock.text')}
          </HighlightedText>
        </div>
      </div>
    </div>
  );
};

export default CoreFeaturesBlock;
