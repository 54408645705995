export enum Platforms {
  any = 'any',
  onlyFans = 'OnlyFans',
  patreon = 'Patreon',
  twitch = 'Twitch',
}

export type Paginate<T> = {
  limit: number;
  offset: number;
  count: number;
  results: T[];
};

export type PaginationParams = Partial<{
  limit: number;
  offset: number;
}>;
