import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import WelcomeImage from 'assets/about-welcome-image.png';
import { HighlightedText } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const WelcomeBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper--black-bg wrapper--with-spots">
      <div className={cn('welcome-block')}>
        <img src={WelcomeImage} alt={t('pages.about.welcomeBlock.title')} />
        <div className={cn('welcome-block__content')}>
          <HighlightedText tag="h1">
            {t('pages.about.welcomeBlock.title')}
          </HighlightedText>
          <h5>{t('pages.about.welcomeBlock.subtitle')}</h5>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBlock;
