import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import i18n from 'lang';
import { useAuthStore } from 'stores';
import { RegisterBody } from 'models';

const signInSchema = yup.object({
  email: yup.string().email().required(),
  'current-password': yup.string().min(8).max(20).required(),
});

const signUpSchema = signInSchema.concat(
  yup.object().shape(
    {
      name: yup
        .string()
        .nullable()
        .notRequired()
        .when('name', {
          is: (value: string | null) => value?.length,
          then: (rule) => rule.min(3).max(50),
        }),
      'confirm-password': yup
        .string()
        .required()
        .oneOf(
          [yup.ref('current-password')],
          i18n.t('errors.validation.repeatPassword')
        ),
    },
    [['name', 'name']]
  )
);

type SignInFormData = yup.InferType<typeof signInSchema>;
type SignUpFormData = yup.InferType<typeof signUpSchema>;
type FormData = SignInFormData | SignUpFormData;

type UseSignUpInFormProps = {
  isSignIn: boolean;
};

const useSignUpInForm = ({ isSignIn }: UseSignUpInFormProps) => {
  const login = useAuthStore((state) => state.login);
  const registerAction = useAuthStore((state) => state.register);
  const resolver = yupResolver(isSignIn ? signInSchema : signUpSchema);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({ resolver, mode: 'all' });

  const isButtonDisabled = !isValid || isSubmitting;
  const onSubmit = handleSubmit(async (values) => {
    const { email, 'current-password': password } = values;

    if (isSignIn) {
      await login({ email, password });
    } else {
      const body: RegisterBody = { email, password };
      const { name } = values as SignUpFormData;

      if (name) {
        body.name = name;
      }

      await registerAction(body);
    }
  });

  return {
    control,
    onSubmit,
    isButtonDisabled,
    isSubmitting,
  };
};

export default useSignUpInForm;
