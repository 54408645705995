import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { IoIosStar } from 'react-icons/io';

import styles from './styles.module.scss';

const cn = classNames.bind(styles);

export type RatingProps = {
  className?: string;
  count?: number;
  value?: number | null;
  clickable?: boolean;
  size?: 'small' | 'large';
  onChange?: (value: number | null) => void;
};

const Rating: FC<RatingProps> = ({
  className,
  count = 5,
  value,
  clickable = true,
  onChange,
  size = 'small',
}) => {
  const rounded = value ? Math.round(value) : null;
  const [activeItem, setActiveItem] = useState<number | null>(rounded);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  useEffect(() => {
    if (activeItem !== null) {
      onChange && onChange(activeItem);
    }
  }, [activeItem]);

  return (
    <div
      className={`${className} ${cn('rating', { 'rating--small': size === 'small', 'rating--large': size === 'large' })}`}
    >
      {[...Array(count).keys()].map((i) => {
        const item = i + 1;
        const isActive =
          hoveredItem !== null
            ? item <= hoveredItem
            : item <= (activeItem ?? 0);

        const Star = IoIosStar;

        return (
          <Star
            key={item}
            className={cn('rating__item', {
              'rating__item--active': isActive,
            })}
            onMouseEnter={() => clickable && setHoveredItem(item)}
            onMouseLeave={() => clickable && setHoveredItem(null)}
            onClick={() => clickable && setActiveItem(item)}
          />
        );
      })}
    </div>
  );
};

export default Rating;
