import React from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { SlGraph } from 'react-icons/sl';
import {
  PiTwitchLogo,
  PiPatreonLogo,
  PiInstagramLogo,
  PiFacebookLogo,
  PiAmazonLogo,
  PiYoutubeLogo,
  PiTwitterLogo,
  PiGlobe,
} from 'react-icons/pi';

import { CreatorLinks as CreatorLinksModel, CreatorLinksKeys } from 'models';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type CreatorLinksProps = {
  links: CreatorLinksModel;
};

const CreatorLinks: React.FC<CreatorLinksProps> = ({ links }) => {
  const items: { icon: IconType; link: string; type: CreatorLinksKeys }[] = [];

  Object.entries(links).forEach(([linkType, link]) => {
    let icon: IconType;
    const type = linkType as CreatorLinksKeys;

    switch (type) {
      case 'facebook':
        icon = PiFacebookLogo;
        break;
      case 'instagram':
        icon = PiInstagramLogo;
        break;
      case 'patreon':
        icon = PiPatreonLogo;
        break;
      case 'twitch':
        icon = PiTwitchLogo;
        break;
      case 'graphtreon':
        icon = SlGraph;
        break;
      case 'twitter':
        icon = PiTwitterLogo;
        break;
      case 'website':
        icon = PiGlobe;
        break;
      case 'wishlist':
        icon = PiAmazonLogo;
        break;
      case 'youtube':
        icon = PiYoutubeLogo;
        break;
    }

    if (link) {
      items.push({ icon, link, type });
    }
  });

  return (
    <div className={classNames(cn('creator-links'))}>
      {items.map(({ icon: Icon, link, type }) => (
        <a key={type} href={link} target="_blank" rel="noreferrer">
          <Icon size={26} />
        </a>
      ))}
    </div>
  );
};

export default CreatorLinks;
