import React, { FC } from 'react';

import CreatorDetailedInfoCards from 'modules/common/CreatorDetailedInfoCards';
import { useProfileStore } from 'stores';

import { MyProfileSettings } from './components';

const MyProfile: FC = () => {
  const profile = useProfileStore((state) => state.profile || null);
  const isLoading = useProfileStore((state) => state.isLoading);
  const creator = profile?.creator || null;
  const isVerified = !!creator?.id;

  return (
    <div className="wrapper">
      <MyProfileSettings isLoading={isLoading} data={profile} />
      {isVerified && (
        <CreatorDetailedInfoCards data={creator} isLoading={isLoading} />
      )}
    </div>
  );
};

export default MyProfile;
