import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import moment from 'moment';

import { ReviewReactions } from 'models';

import styles from './styles.module.scss';
import Avatar from '../../Avatar';

const cn = classNamesBind.bind(styles);

type ReactionsProps = {
  reactions?: ReviewReactions | null;
};

const Reactions: FC<ReactionsProps> = ({ reactions }) => {
  if (!reactions || reactions.length === 0) {
    return <div />;
  }

  return (
    <div className={cn('reactions')}>
      {reactions.map(({ comment, avatar, name, createdAt }, index) => {
        const parsedCreatedAt = createdAt
          ? moment(Date.parse(createdAt)).format('MMM Do, YYYY')
          : null;

        return (
          <div key={index} className={cn('reaction')}>
            <div>
              <Avatar src={avatar} />
              <div>
                {name && <h6>{name}</h6>}
                {parsedCreatedAt && <p>{parsedCreatedAt}</p>}
              </div>
            </div>
            {comment && <p>{comment}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default Reactions;
