import React from 'react';

import { Platforms } from 'models';

import PlatformCategories from '../PlatformCategories';

const OnlyfansBlock = () => {
  return (
    <div className="wrapper wrapper--with-spots">
      <PlatformCategories platform={Platforms.onlyFans} />
    </div>
  );
};

export default OnlyfansBlock;
