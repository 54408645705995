import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PATHS } from 'router';
import HomeCreatorImg from 'assets/home-creator-image.png';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const CreatorBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper wrapper--with-spots">
      <div className={cn('creator-block')}>
        <img src={HomeCreatorImg} alt={t('pages.home.creatorBlock.title')} />
        <div className={cn('creator-block__info')}>
          <h2>{t('pages.home.creatorBlock.title')}</h2>
          <h5>{t('pages.home.creatorBlock.subtitle')}</h5>
          <Link to={PATHS.creators}>{t('pages.home.creatorBlock.action')}</Link>
        </div>
      </div>
    </div>
  );
};

export default CreatorBlock;
