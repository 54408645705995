import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { creatorsApi, GetFavoritesReturn, GetFavoritesParams } from 'api';
import { Paginate, Creator } from 'models';
import { FetchOptions } from 'types';

type State = {
  favorites: GetFavoritesReturn;
  isLoading: boolean;
};

type Actions = {
  clear: () => void;
  fetch: (params?: GetFavoritesParams, oprions?: FetchOptions) => Promise<void>;
  setLoading: (value: boolean) => void;
  setFavorites: (favorites: Paginate<Creator>) => void;
  addToFavorites: (creatorId: string) => Promise<boolean>;
  removeFromFavorites: (creatorId: string) => Promise<boolean>;
};

export type FavoritesStore = State & Actions;

const defaultState: State = {
  favorites: null,
  isLoading: false,
};

const useFavoritesStore = create<FavoritesStore>()(
  immer((set, get) => ({
    ...defaultState,

    clear: () => set(() => defaultState),

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setFavorites: (favorites) =>
      set((state) => {
        state.favorites = favorites;
      }),

    fetch: async (params, { showLoader = true } = {}) => {
      const { setLoading, setFavorites } = get();

      if (showLoader) setLoading(true);

      const res = await creatorsApi.favorites(params);

      if (showLoader) setLoading(false);

      if (res !== null) {
        setFavorites(res);
      }
    },

    addToFavorites: async (creatorId) => {
      const res = await creatorsApi.addToFavorites(creatorId);

      if (res === null) return false;

      get().fetch();

      return true;
    },

    removeFromFavorites: async (creatorId) => {
      const res = await creatorsApi.removeFromFavorites(creatorId);

      if (res === null) return false;

      get().fetch();

      return true;
    },
  }))
);

export const useFetchFavorites = () => {
  const data = useFavoritesStore((state) => state.favorites);
  const isLoading = useFavoritesStore((state) => state.isLoading);

  useEffect(() => {
    if (data === null) {
      useFavoritesStore.getState().fetch();
    }
  }, []);

  return { data, isLoading };
};

export default useFavoritesStore;
