import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { DetailedCreator } from 'models';

import { RightCard, LeftCard } from './components';
import { FavoriteAndViewButtonsProps } from './components/FavoriteAndViewButtons';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type CreatorDetailedInfoCardsProps = Pick<
  FavoriteAndViewButtonsProps,
  'toggleFavorite'
> & {
  data: DetailedCreator | null;
  isLoading?: boolean;
};

const CreatorDetailedInfoCards: FC<CreatorDetailedInfoCardsProps> = ({
  data,
  isLoading = false,
  toggleFavorite,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={cn('account')}>
        <Skeleton height={500} borderRadius={12} />
        <Skeleton height={500} borderRadius={12} />
      </div>
    );
  }

  if (!data || !data?.id) {
    return <h5>{t('noData.common')}</h5>;
  }

  return (
    <div className={cn('account')}>
      <LeftCard data={data} />
      <RightCard data={data} toggleFavorite={toggleFavorite} />
    </div>
  );
};

export default CreatorDetailedInfoCards;
