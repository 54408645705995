import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import BetweenImage from 'assets/about-between-image.png';

import styles from './styles.module.scss';
import { HighlightedText } from 'components/uikit';

const cn = classNamesBind.bind(styles);

const BetweenBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper wrapper--with-spots">
      <div className={cn('between-block')}>
        <div className={cn('between-block__content')}>
          <HighlightedText tag="h2">
            {t('pages.about.gapBetweenBlock.title')}
          </HighlightedText>
          <h5>{t('pages.about.gapBetweenBlock.subtitle')}</h5>
        </div>
        <div>
          <img
            src={BetweenImage}
            alt={t('pages.about.gapBetweenBlock.title')}
          />
        </div>
      </div>
    </div>
  );
};

export default BetweenBlock;
