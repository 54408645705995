import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';

import ParsedHtml, { ParsedHtmlProps } from '../ParsedHtml';
import styles from './styles.module.scss';

const cn = classNames.bind(styles);

type HighlightedTextProps = ParsedHtmlProps;

const HighlightedText: FC<PropsWithChildren<HighlightedTextProps>> = ({
  tag = 'h1',
  children,
  className,
  ...rest
}) => {
  return (
    <ParsedHtml
      {...rest}
      tag={tag}
      className={`${className} ${cn('highlighted')}`}
    >
      {children}
    </ParsedHtml>
  );
};

export default HighlightedText;
