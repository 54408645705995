import React, { FC, PropsWithChildren } from 'react';
import classNamesBind from 'classnames/bind';

import { HighlightedText, Slider, SliderProps } from 'components/uikit';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const cn = classNamesBind.bind(styles);

type SliderWithTitleProps = {
  title: string;
  grayBg?: boolean;
  showNoData?: boolean;
  sliderProps?: SliderProps;
};

const SliderWithTitle: FC<PropsWithChildren<SliderWithTitleProps>> = ({
  title,
  grayBg = false,
  showNoData = false,
  sliderProps,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('top-creators-block', {
        'top-creators-block--gray-bg': grayBg,
      })}
    >
      <HighlightedText tag="h3">{title}</HighlightedText>
      {showNoData ? (
        <h5>{t('noData.common')}</h5>
      ) : (
        <Slider {...sliderProps}>{children}</Slider>
      )}
    </div>
  );
};

export default SliderWithTitle;
