import React from 'react';
import { IoIosStar } from 'react-icons/io';

import i18n from 'lang';
import OnlyfansIcon from 'assets/only-fans.svg';
import PatreonIcon from 'assets/patreon.svg';
import TwitchsIcon from 'assets/twitch.svg';
import { Platforms, CreatorRating, CreatorPrice, CreatorGender } from 'models';

const starIcon = <IoIosStar color="orange" size={12} />;

export const platformItems = [
  {
    value: Platforms.any,
    label: i18n.t('common.any'),
  },
  {
    value: Platforms.onlyFans,
    icon: <img src={OnlyfansIcon} />,
  },
  {
    value: Platforms.patreon,
    icon: <img src={PatreonIcon} />,
  },
  {
    value: Platforms.twitch,
    icon: <img src={TwitchsIcon} />,
  },
];

export const ratingItems = [
  { value: CreatorRating.any, label: i18n.t('common.any') },
  { value: CreatorRating.twoPlus, icon: starIcon },
  { value: CreatorRating.threePlus, icon: starIcon },
  { value: CreatorRating.fourPlus, icon: starIcon },
  { value: CreatorRating.fivePlus, icon: starIcon },
];

export const priceItems = [
  { value: CreatorPrice.any, label: i18n.t('common.any') },
  {
    label: i18n.t('pages.category.filters.prices.freeOnly'),
    value: CreatorPrice.freeOnly,
  },
  {
    label: i18n.t('pages.category.filters.prices.fiveAndUnder'),
    value: CreatorPrice.fiveAndUnder,
  },
  {
    label: i18n.t('pages.category.filters.prices.fiveToFifteen'),
    value: CreatorPrice.fiveToFifteen,
  },
  {
    label: i18n.t('pages.category.filters.prices.fifteenAndOver'),
    value: CreatorPrice.fifteenAndOver,
  },
];

export const genderItems = [
  {
    label: i18n.t('pages.category.filters.genders.all'),
    value: CreatorGender.all,
  },
  {
    label: i18n.t('pages.category.filters.genders.female'),
    value: CreatorGender.female,
  },
  {
    label: i18n.t('pages.category.filters.genders.male'),
    value: CreatorGender.male,
  },
  {
    label: i18n.t('pages.category.filters.genders.unknown'),
    value: CreatorGender.unknown,
  },
];
