import React, { useState } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import LogoColorSvg from 'assets/logo-color.svg';

import styles from './styles.module.scss';
import { Menu, MobileMenuIcon } from './components';

const cn = classNamesBind.bind(styles);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className={classNames(cn('header-wrapper'), 'wrapper')}>
      <div className={cn('header')}>
        <Link to="/">
          <img src={LogoColorSvg} alt="Logo white" />
        </Link>
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
        <MobileMenuIcon isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </header>
  );
};

export default Header;
