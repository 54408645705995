import React, { FC, Fragment, useState } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { useDisableOverflowY } from 'hooks';
import { useAuthStore } from 'stores';

import useCreatorStore from '../../store';
import LeaveReviewForm from '../LeaveReviewForm';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const LeaveReviewButton: FC = () => {
  const { t } = useTranslation();
  const isReviewed = useCreatorStore(
    (state) => state.creator?.reviewed || false
  );
  const isAuth = useAuthStore((state) => !!state.accessToken);
  const [isOpen, setIsOpen] = useState(false);
  useDisableOverflowY(isOpen);

  const hideButton = isReviewed || !isAuth;

  if (hideButton) return null;

  return (
    <Fragment>
      <button
        className={cn('leave-review-button')}
        onClick={() => setIsOpen(true)}
      >
        {t('pages.creator.actions.leaveReview')}
      </button>
      {isOpen && <LeaveReviewForm setIsOpen={setIsOpen} />}
    </Fragment>
  );
};

export default LeaveReviewButton;
