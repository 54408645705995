import queryString from 'query-string';

const parse: typeof queryString.parse = (query, options) =>
  queryString.parse(query, {
    parseBooleans: true,
    arrayFormat: 'index',
    ...options,
  });

const stringify: typeof queryString.stringify = (object, options) =>
  queryString.stringify(object, {
    skipNull: true,
    arrayFormat: 'index',
    skipEmptyString: true,
    ...options,
  });

const appQueryString = {
  ...queryString,
  stringify,
  parse,
};

export default appQueryString;
