import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreatorCard } from 'components/uikit';
import { CreatorSort } from 'models';

import SliderWithTitle from '../SliderWithTitle';
import { useFetchCreators } from './store';

const RecentlyVerifiedCreators = () => {
  const { t } = useTranslation();
  const title = t('pages.home.recentlyVerifiedCreators');
  const { data, isLoading } = useFetchCreators({
    limit: 6,
    sort: CreatorSort.verified,
  });

  return (
    <SliderWithTitle
      title={title}
      grayBg
      showNoData={!isLoading && (data === null || data?.count === 0)}
      sliderProps={{ isLoading, skeletonProps: { height: 580 } }}
    >
      {data?.results?.map(
        ({
          id,
          price,
          media,
          name,
          platform,
          bio,
          rating,
          promoPrice,
          promoOff,
          username,
          counters,
        }) => (
          <CreatorCard
            key={id}
            priceInCents={price}
            promoPriceInCents={promoPrice}
            promoOff={promoOff}
            imageSrc={media?.avatar || null}
            platform={platform}
            likes={counters?.likes || null}
            name={name}
            username={username}
            bio={bio}
            rating={rating}
          />
        )
      )}
    </SliderWithTitle>
  );
};

export default RecentlyVerifiedCreators;
