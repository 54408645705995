import React from 'react';
import { redirect, RouteObject } from 'react-router-dom';

import { ReviewsForOthers, ReviewsLeftForMe } from 'modules';

import PATHS from '../paths';
import ProtectedRoute from '../ProtectedRoute';

const reviewsRoute: RouteObject = {
  path: PATHS.reviewsKey,
  children: [
    {
      index: true,
      loader: () => {
        return redirect(PATHS.reviewsForOthers);
      },
    },
    {
      path: PATHS.reviewsForOthersKey,
      element: (
        <ProtectedRoute from="notAuthUser">
          <ReviewsForOthers />
        </ProtectedRoute>
      ),
    },
    {
      path: PATHS.reviewsLeftForMeKey,
      element: (
        <ProtectedRoute from="notCreator">
          <ReviewsLeftForMe />
        </ProtectedRoute>
      ),
    },
  ],
};

export default reviewsRoute;
