import React from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBind from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from 'stores';
import { TextInput } from 'components/form';
import { useQueryString } from 'hooks';
import { PATHS } from 'router';
import { CircularIndicator } from 'components/uikit';
import AuthLayout from 'modules/Auth/AuthLayout';
import i18n from 'lang';

import styles from './styles.module.scss';

const schema = yup.object({
  'new-password': yup.string().min(8).max(20).required(),
  'confirm-password': yup
    .string()
    .required()
    .oneOf(
      [yup.ref('new-password')],
      i18n.t('errors.validation.repeatPassword')
    ),
});

type FormData = yup.InferType<typeof schema>;

const cn = classNamesBind.bind(styles);

const ResetPassword = () => {
  const {
    params: { token },
  } = useQueryString<{ token?: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(schema), mode: 'all' });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ 'new-password': password }) => {
    if (token) {
      const success = await useAuthStore
        .getState()
        .setPassword({ password, token });
      if (success) navigate(PATHS.signIn);
    }
  });

  return (
    <AuthLayout formClassName={cn('reset-password')}>
      <h5>{t('pages.resetPassword.title')}</h5>
      <form onSubmit={onSubmit}>
        <TextInput
          name="new-password"
          control={control}
          componentProps={{
            type: 'password',
            underlined: true,
            placeholder: t('pages.resetPassword.newPassword'),
          }}
        />
        <TextInput
          name="confirm-password"
          control={control}
          componentProps={{
            type: 'password',
            underlined: true,
            placeholder: t('pages.resetPassword.repeatPassword'),
          }}
        />
        <button
          disabled={isButtonDisabled}
          type="submit"
          className="button--primary"
        >
          {t('pages.resetPassword.action')}
          {isSubmitting && <CircularIndicator />}
        </button>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
