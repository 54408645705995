import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type PaginateProps = Omit<ReactPaginateProps, 'pageCount'> & {
  perPage?: number;
  itemsCount: number;
  pageCount?: number;
};

export type ClickEvent = {
  index: number | null;
  selected: number;
  nextSelectedPage: number | undefined;
  event: object;
  isPrevious: boolean;
  isNext: boolean;
  isBreak: boolean;
  isActive: boolean;
};

const Paginate: FC<PaginateProps> = ({
  className,
  itemsCount,
  perPage = 10,
  ...rest
}) => {
  const { t } = useTranslation();
  const pageCount = Math.ceil(itemsCount / perPage);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={t('components.paginate.next')}
      previousLabel={t('components.paginate.previous')}
      pageRangeDisplayed={1}
      renderOnZeroPageCount={null}
      activeClassName={cn('paginate--active')}
      disabledClassName={cn('paginate--disabled')}
      pageClassName={cn('paginate__page-item')}
      breakClassName={cn('paginate__break-item')}
      pageCount={pageCount === 1 ? 0 : pageCount}
      {...rest}
      className={classNames(className, cn('paginate'))}
    />
  );
};

export default Paginate;
