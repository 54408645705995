import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNamesBind from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AuthLayout from 'modules/Auth/AuthLayout';
import { CircularIndicator } from 'components/uikit';
import { TextInput } from 'components/form';
import { PATHS } from 'router';
import { useAuthStore } from 'stores';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const schema = yup.object({
  email: yup.string().email().required(),
});

type FormData = yup.InferType<typeof schema>;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(schema), mode: 'all' });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async (values) => {
    await useAuthStore.getState().forgotPassword(values);
  });

  return (
    <AuthLayout formClassName={cn('forgot-password')}>
      <h5>{t('pages.forgotPassword.title')}</h5>
      <p>{t('pages.forgotPassword.subtitle')}</p>
      <form onSubmit={onSubmit}>
        <TextInput
          name="email"
          control={control}
          componentProps={{
            type: 'email',
            underlined: true,
            placeholder: t('inputs.email'),
          }}
        />
        <button
          disabled={isButtonDisabled}
          type="submit"
          className="button--primary"
        >
          {t('pages.forgotPassword.action')}
          {isSubmitting && <CircularIndicator />}
        </button>
      </form>
      <Link to={PATHS.signIn} className="button--secondary">
        {t('actions.login')}
      </Link>
    </AuthLayout>
  );
};

export default ForgotPassword;
