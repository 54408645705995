import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { CreatorSearchQueryParams, CreatorSort } from 'models';
import { useQueryString } from 'hooks';
import { Dropdown } from 'components/uikit';

import useSearchCreatorsStore from '../../store';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const CountBlock: FC = () => {
  const { t } = useTranslation();
  const { params, updateParams } = useQueryString<CreatorSearchQueryParams>();
  const count = useSearchCreatorsStore((state) => state.creators?.count || 0);
  const limit = useSearchCreatorsStore((state) => state.creators?.limit || 0);
  const offset = useSearchCreatorsStore((state) => state.creators?.offset || 0);

  const sortByItems = [
    {
      value: CreatorSort.relevant,
      label: t('pages.category.sortBy.mostRelevant'),
    },
    { value: CreatorSort.liked, label: t('pages.category.sortBy.mostLiked') },
    { value: CreatorSort.rated, label: t('pages.category.sortBy.mostRated') },
    {
      value: CreatorSort.subscribers,
      label: t('pages.category.sortBy.mostSubscribers'),
    },
  ];

  return (
    <div className={cn('count-block')}>
      <p>
        {t('pages.category.results', {
          from: offset,
          to: offset + limit,
          total: count,
        })}
      </p>
      <Dropdown
        defaultValue={params.sort}
        options={sortByItems}
        onChange={({ target: { value } }) =>
          updateParams({ sort: value as CreatorSort })
        }
      />
    </div>
  );
};

export default CountBlock;
