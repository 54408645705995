import React from 'react';
import classNamesBind from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Review, Paginate } from 'components/uikit';
import { useAuthStore } from 'stores';

import useCreatorStore, { useFetchCreatorReviews } from '../../store';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type ReviewsList = ReturnType<typeof useFetchCreatorReviews>;

const ReviewsList: React.FC<ReviewsList> = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => !!state.accessToken);

  if (isLoading) {
    return (
      <div className={cn('reviews-list')}>
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
      </div>
    );
  }

  if (!data || data.count === 0) {
    return <h5>{t('noData.reviews')}</h5>;
  }
  const items = data.results;

  const onToggleHelpful = (reviewId: string | null) => () => {
    if (!reviewId) return;

    useCreatorStore.getState().toogleHelpful(reviewId);
  };

  return (
    <div className={cn('reviews-list')}>
      {items.map((review) => (
        <Review
          key={review.id}
          canRate={false}
          review={review}
          onToggleHelpful={isAuth ? onToggleHelpful(review.id) : undefined}
        />
      ))}
    </div>
  );
};

const PaginatedReviewsList = () => {
  const { creatorUsername } = useParams();
  const { data, isLoading } = useFetchCreatorReviews(creatorUsername || '');

  const handlePageClick = ({ selected }: { selected: number }) => {
    const store = useCreatorStore.getState();
    const username = store.creator?.username || creatorUsername || '';
    const limit = store.reviews?.data?.limit || 0;
    const offset = limit * selected;

    store.fetchReviews({ username, offset }, { showLoader: false });
  };

  return (
    <div>
      <ReviewsList data={data} isLoading={isLoading} />
      <Paginate onPageChange={handlePageClick} itemsCount={data?.count || 0} />
    </div>
  );
};

export default PaginatedReviewsList;
