import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ReplyDialogProps } from './ReplyDialog';

const schema = yup.object({
  comment: yup.string().min(3).max(1000).required(),
});

type FormData = yup.InferType<typeof schema>;

const useReplyForm = ({
  setIsOpen,
  onReply,
}: Pick<ReplyDialogProps, 'setIsOpen' | 'onReply'>) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const isButtonDisabled = !isValid || isSubmitting;
  const onSubmit = handleSubmit(async (values) => {
    await onReply(values);
    setIsOpen(false);
  });

  return { control, onSubmit, isButtonDisabled, isSubmitting };
};

export default useReplyForm;
