import React from 'react';
import { motion } from 'framer-motion';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import LogoColorSvg from 'assets/logo-color.svg';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const Splash = () => {
  return (
    <div
      className={classNames(
        cn('splash'),
        'wrapper',
        'wrapper--black-bg',
        'wrapper--with-spots'
      )}
    >
      <motion.div
        initial={{ zoom: 1.6 }}
        animate={{ zoom: 1.2 }}
        transition={{ ease: 'easeOut', duration: 2 }}
      >
        <img src={LogoColorSvg} alt="Logo white" />
      </motion.div>
    </div>
  );
};

export default Splash;
