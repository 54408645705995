import React from 'react';
import classNamesBind from 'classnames/bind';

import ReviewsHeaderBlock from '../ReviewsHeaderBlock';
import ReviewsStatisticBlock from '../ReviewsStatisticBlock';
import ReviewsList from '../ReviewsList';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ReviewsBlock: React.FC = () => {
  return (
    <div className="wrapper">
      <div className={cn('reviews-block')}>
        <ReviewsHeaderBlock />
        <ReviewsStatisticBlock />
        <ReviewsList />
      </div>
    </div>
  );
};

export default ReviewsBlock;
