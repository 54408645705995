import React, { FC, ChangeEvent } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Dropdown } from 'components/uikit';
import { ReviewSortBy } from 'api';
import { Option } from 'types';

import useCreatorStore from '../../store';
import LeaveReviewButton from '../LeaveReviewButton';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ReviewsHeaderBlock: FC = () => {
  const { t } = useTranslation();
  const isLoading = useCreatorStore((state) => state.isCreatorFetching);
  const reviewCount = useCreatorStore(
    (state) => state.creator?.reviewsStatistic?.reviewCount || 0
  );

  if (isLoading) {
    return <div />;
  }

  const dropdownOptions: Option[] = [
    {
      label: t('pages.creator.reviewsSortBy.mostRecent'),
      value: ReviewSortBy.recent,
    },
    {
      label: t('pages.creator.reviewsSortBy.mostHelpful'),
      value: ReviewSortBy.helpful,
    },
    {
      label: t('pages.creator.reviewsSortBy.mostNegative'),
      value: ReviewSortBy.negative,
    },
    {
      label: t('pages.creator.reviewsSortBy.mostPositive'),
      value: ReviewSortBy.positive,
    },
  ];

  const onSortByChanged = (e: ChangeEvent<HTMLSelectElement>): void => {
    const creatorStore = useCreatorStore.getState();
    const username = creatorStore.creator?.username;

    if (!username) return;

    useCreatorStore.getState().fetchReviews(
      {
        username,
        sort: e.target.value as ReviewSortBy,
      },
      { showLoader: false }
    );
  };

  return (
    <div className={cn('reviews-header-block')}>
      <div>
        <h5>{t('pages.creator.reviews', { total: reviewCount })}</h5>
        <Dropdown options={dropdownOptions} onChange={onSortByChanged} />
      </div>
      <LeaveReviewButton />
    </div>
  );
};

export default ReviewsHeaderBlock;
