import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { enqueueSnackbar } from 'notistack';

import { Tokens, LoginBody, RegisterBody } from 'models';
import { authApi, http, ForgotPasswordBody, SetPasswordBody } from 'api';
import storage from 'services/storage';
import i18n from 'lang';

import useProfileStore from './profile';
import useFavoritesStore from './favorites';
import useMyReviewsStore from './reviews';

type State = Tokens;

type Actions = {
  logout: () => void;
  refresh: () => Promise<void>;
  setTokenToHeadersFromStore: () => void;
  login: (body: LoginBody) => Promise<void>;
  register: (body: RegisterBody) => Promise<void>;
  setPassword: (body: SetPasswordBody) => Promise<boolean>;
  forgotPassword: (body: ForgotPasswordBody) => Promise<boolean>;
};

export type AuthStore = State & Actions;

const defaultState: State = {
  accessToken: null,
  refreshToken: null,
};

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      ...defaultState,

      setTokenToHeadersFromStore: () => {
        const { accessToken } = get();

        if (accessToken) {
          http.setAuthTokenToHeaders(accessToken);
        }
      },

      login: async (body) => {
        const res = await authApi.login(body);

        if (res === null) {
          return;
        }

        if (res.accessToken !== null) {
          set(() => ({
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          }));
          http.setAuthTokenToHeaders(res.accessToken);
          useProfileStore.getState().setProfile(res.data);
        }
      },

      register: async (body) => {
        const res = await authApi.register(body);

        if (res === null) {
          return;
        }

        if (res.accessToken !== null) {
          set(() => ({
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          }));
          http.setAuthTokenToHeaders(res.accessToken);
          useProfileStore.getState().setProfile(res.data);
        }
      },

      refresh: async () => {
        const refreshToken = get().refreshToken;

        if (!refreshToken) {
          return get().logout();
        }

        http.setAuthTokenToHeaders(refreshToken);
        const res = await authApi.refreshTokens();

        if (res !== null && res.accessToken !== null) {
          set(() => ({
            accessToken: res.accessToken,
            refreshToken: res.refreshToken,
          }));
          http.setAuthTokenToHeaders(res.accessToken);
          useProfileStore.getState().setProfile(res.data);
        } else {
          get().logout();
        }
      },

      logout: () => {
        set(() => defaultState);
        useProfileStore.getState().clear();
        useFavoritesStore.getState().clear();
        useMyReviewsStore.getState().clear();
        http.clearAuthTokenFromHeaders();
      },

      setPassword: async (body) => {
        const res = await authApi.setPassword(body);

        if (res === null) return false;

        enqueueSnackbar(i18n.t('notify.changePassword'), {
          variant: 'success',
          hideIconVariant: true,
        });

        return true;
      },

      forgotPassword: async (body) => {
        const res = await authApi.forgotPassword(body);

        if (res === null) return false;

        enqueueSnackbar(i18n.t('notify.sendResetPasswordLink'), {
          variant: 'success',
          hideIconVariant: true,
        });

        return true;
      },
    }),
    {
      name: storage.AUTH_STORE_KEY,
    }
  )
);

export default useAuthStore;
