import React from 'react';
import classNamesBind from 'classnames/bind';
import {} from 'react-paginate';

import { CreatorSearchQueryParams } from 'models';
import { Paginate, PaginateClickEvent } from 'components/uikit';
import { useQueryString } from 'hooks';

import useSearchCreatorsStore from '../../store';
import CreatorsList from './CreatorsList';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const CreatorsBlock = () => {
  const {
    params: { offset },
    updateParams,
  } = useQueryString<CreatorSearchQueryParams>();
  const data = useSearchCreatorsStore((state) => state.creators);
  const isLoading = useSearchCreatorsStore((state) => state.isFetching);
  const page = (offset || 0) / 10;
  const itemsCount = data?.count || 0;

  const handlePageClick = ({
    selected,
    nextSelectedPage,
  }: PaginateClickEvent) => {
    const page = nextSelectedPage ?? selected;
    const store = useSearchCreatorsStore.getState();
    const limit = store.creators?.limit || 0;
    const offset = limit * page;

    updateParams({ offset });
    setTimeout(() => window.scrollTo({ top: 400, behavior: 'smooth' }), 100);
  };

  return (
    <div className={cn('creators')}>
      <CreatorsList data={data} isLoading={isLoading} />
      <Paginate
        itemsCount={itemsCount}
        forcePage={page}
        onClick={handlePageClick}
      />
    </div>
  );
};

export default CreatorsBlock;
