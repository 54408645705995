import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ToggleButton } from 'components/uikit';
import { Review as MReview } from 'models';

import styles from './styles.module.scss';
import { Reactions, ReplyButton, ReplyButtonProps } from './components';
import Avatar from '../Avatar';
import Rating from '../Rating';
import { useProfileStore } from 'stores';

const cn = classNamesBind.bind(styles);

type ReviewProps = {
  canRate?: boolean;
  review: MReview;
  className?: string;
  onToggleHelpful?: (checked: boolean) => void;
  onReply?: ReplyButtonProps['onReply'];
};

const Review: FC<ReviewProps> = ({
  canRate = true,
  className,
  review,
  onToggleHelpful,
  onReply,
}) => {
  const { t } = useTranslation();
  const {
    createdAt,
    avatarUrl,
    name,
    creatorName,
    rating,
    helpful,
    comment,
    positive,
    negative,
    reactions,
    userId,
  } = review;
  const profileId = useProfileStore((state) => state.profile?.id);
  const showHelpful = !!onToggleHelpful && profileId !== userId;
  const parsedDate = createdAt
    ? moment(Date.parse(createdAt)).format('MMM Do, YYYY')
    : null;

  return (
    <div className={classNames(cn('review'), className)}>
      <div className={cn('review__header')}>
        <div className={cn('review__header-info')}>
          <Avatar src={avatarUrl} />
          <div>
            {name && creatorName && (
              <h6>
                {name} {t('components.review.reviewed')} {creatorName}
              </h6>
            )}
            {parsedDate && <p>{parsedDate}</p>}
          </div>
        </div>
        <div className={cn('review__header-actions')}>
          <Rating clickable={canRate} value={rating || 0} />
          {onReply && <ReplyButton onReply={onReply} />}
          {showHelpful && (
            <ToggleButton
              checked={helpful ?? false}
              label={t('components.review.helpful')}
              onChange={({ target: { checked } }) => onToggleHelpful(checked)}
            />
          )}
        </div>
      </div>
      <p>{comment}</p>
      {positive && (
        <div className={cn('review__tags')}>
          {positive.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div>
      )}
      {negative && (
        <div className={cn('review__tags', 'review__tags--negative')}>
          {negative.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div>
      )}
      <Reactions reactions={reactions} />
    </div>
  );
};

export default Review;
