import React, { FC, Fragment } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Platforms } from 'models';
import { CategoryTag } from 'components/uikit';
import { getPlatformColorLogo } from 'utils';
import { useCategoriesStore } from 'stores';
import { PATHS } from 'router';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type CategoriesListType = {
  platform: Platforms;
};

const CategoriesList: FC<CategoriesListType> = ({ platform }) => {
  const { t } = useTranslation();
  const categories = useCategoriesStore((state) =>
    state.data?.filter((el) => el.platform === platform)
  );
  const isLoading = useCategoriesStore((state) => state.isLoading);

  if (isLoading) {
    return (
      <Fragment>
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} width={150} height={50} borderRadius={32} />
        ))}
      </Fragment>
    );
  }

  if (!categories || categories?.length === 0) {
    return <h5>{t('noData.common')}</h5>;
  }

  return (
    <Fragment>
      {categories.map(({ icon, name }) => (
        <CategoryTag
          to={PATHS.getSearch({ category: name })}
          key={name}
          emoji={icon}
        >
          {name}
        </CategoryTag>
      ))}
    </Fragment>
  );
};

type PlatformCategoriesType = {
  platform: Platforms;
};

const PlatformCategories: FC<PlatformCategoriesType> = ({ platform }) => {
  return (
    <div className={cn('title-with-categories')}>
      <div className={cn('title-with-categories__title')}>
        <img src={getPlatformColorLogo(platform)} alt={platform} />
        <h3>{platform}</h3>
      </div>
      <div className={cn('title-with-categories__content')}>
        <CategoriesList platform={platform} />
      </div>
    </div>
  );
};

export default PlatformCategories;
