import { useState, useCallback, useEffect } from 'react';

export type Options<ArgumentsT = void> = {
  instantFetch?: boolean;
  args?: ArgumentsT;
};

function useFetch<ReturnT, ArgumentsT = void>(
  apiCall: (args: ArgumentsT) => Promise<ReturnT>,
  { instantFetch = false, args }: Options<ArgumentsT> = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ReturnT | null>(null);

  const fetch = useCallback(async (args: ArgumentsT) => {
    setIsLoading(true);

    const res = await apiCall(args);

    setIsLoading(false);

    if (res) {
      setData(res);
    }

    return res;
  }, []);

  useEffect(() => {
    if (instantFetch && args) {
      fetch(args);
    }
  }, []);

  return { isLoading, data, fetch };
}

export default useFetch;
