import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import {
  Home,
  Layout,
  Categories,
  About,
  Error,
  ForCreators,
  Privacy,
  Terms,
  SearchCreators,
  Creator,
  MyFavorites,
  SignUpIn,
  ForgotPassword,
  ResetPassword,
  MyProfile,
} from 'modules';

import PATHS from './paths';
import ProtectedRoute from './ProtectedRoute';
import { reviewsRoute, verificationRoute } from './routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: PATHS.error,
        element: <Error />,
      },
      {
        path: PATHS.search,
        element: <SearchCreators />,
      },
      {
        path: PATHS.categories,
        element: <Categories />,
      },
      {
        path: PATHS.creators,
        element: <ForCreators />,
      },
      {
        path: PATHS.creatorKey,
        element: <Creator />,
      },
      {
        path: PATHS.privacy,
        element: <Privacy />,
      },
      {
        path: PATHS.about,
        element: <About />,
      },
      {
        path: PATHS.terms,
        element: <Terms />,
      },
      reviewsRoute,
      verificationRoute,
      {
        path: PATHS.myProfile,
        element: (
          <ProtectedRoute from="notAuthUser">
            <MyProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.myFavorites,
        element: (
          <ProtectedRoute from="notAuthUser">
            <MyFavorites />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.signIn,
        element: (
          <ProtectedRoute from="authUser">
            <SignUpIn isSignIn={true} />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.signUp,
        element: (
          <ProtectedRoute from="authUser">
            <SignUpIn isSignIn={false} />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.forgotPassword,
        element: (
          <ProtectedRoute from="authUser">
            <ForgotPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.resetPassword,
        element: (
          <ProtectedRoute from="authUser">
            <ResetPassword />
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
]);

export default router;
