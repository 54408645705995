import React from 'react';
import { useTranslation } from 'react-i18next';

import useLoadHtmlTemplate from 'hooks/useLoadHtmlTemplate';

const Terms = () => {
  const { t } = useTranslation();
  const { htmlContent } = useLoadHtmlTemplate('/htmlTemplates/TOS.html');

  return (
    <div className="wrapper">
      <h5>{t('pages.terms.name')}</h5>
      <div dangerouslySetInnerHTML={{ __html: htmlContent ?? '' }} />
    </div>
  );
};

export default Terms;
