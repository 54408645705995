import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { TextArea, TextAreaProps } from '../uikit';
import { DefaultInputProps } from './types';

const TextAreaController = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultInputProps<FormValues, TextAreaProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  return <TextArea {...field} error={error} {...componentProps} />;
};

export default TextAreaController;
