import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './styles.module.scss';
import Header from './Header';
import Footer from './Footer';

const cn = classNames.bind(styles);

const Layout = () => {
  return (
    <React.Fragment>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <Header />
      <main className={cn('main')}>
        <Outlet />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
