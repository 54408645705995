import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Profile } from 'models';

import MainProfileInfoForm from '../MainProfileInfoForm';
import ChangePasswordForm from '../ChangePasswordForm';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type MyProfileSettingsProps = {
  data: Omit<Profile, 'creator'> | null;
  isLoading?: boolean;
};

const MyProfileSettings: FC<MyProfileSettingsProps> = ({
  data,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton height={300} borderRadius={12} width="100%" />;
  }

  if (!data || !data?.id) {
    return <h5>{t('noData.common')}</h5>;
  }

  return (
    <div className={cn('profile-settings')}>
      <h3>{t('pages.account.mySettings')}</h3>
      <div>
        <MainProfileInfoForm
          name={data.name}
          email={data.email}
          avatarUrl={data.avatarUrl}
        />
        <ChangePasswordForm />
      </div>
    </div>
  );
};

export default MyProfileSettings;
