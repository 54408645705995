import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PATHS } from 'router';
import { Platforms } from 'models';
import OnlyFans from 'assets/only-fans.svg';
import Patreon from 'assets/patreon.svg';
import Twitch from 'assets/twitch.svg';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const BrowseByBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <div className={cn('browse-by-block')}>
        <h3>{t('pages.home.browseByBlock.title')}</h3>
        <div>
          <Link to={PATHS.getSearch({ platform: Platforms.onlyFans })}>
            <img src={OnlyFans} alt={Platforms.onlyFans} />
            <h5>{Platforms.onlyFans}</h5>
          </Link>
          <Link to={PATHS.getSearch({ platform: Platforms.patreon })}>
            <img src={Patreon} alt={Platforms.patreon} />
            <h5>{Platforms.patreon}</h5>
          </Link>
          <Link to={PATHS.getSearch({ platform: Platforms.twitch })}>
            <img src={Twitch} alt={Platforms.twitch} />
            <h5>{Platforms.twitch}</h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BrowseByBlock;
