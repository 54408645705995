import React from 'react';

import { Platforms } from 'models';

import TitleWithCategories from '../PlatformCategories';

const PatreonBlock = () => {
  return (
    <div className="wrapper">
      <TitleWithCategories platform={Platforms.twitch} />
    </div>
  );
};

export default PatreonBlock;
