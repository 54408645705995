import React, { Fragment, useState } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { HiOutlineFilter } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';

import { useDisableOverflowY } from 'hooks';

import FilterBlock from '../FilterBlock';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const FilterButton = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useDisableOverflowY(isOpen);

  return (
    <Fragment>
      <button className={cn('filter-button')} onClick={() => setIsOpen(true)}>
        <p>{t('pages.category.filters.title')}</p>
        <HiOutlineFilter size={22} />
      </button>
      {isOpen && (
        <div className={cn('mobile-filters')}>
          <div className={cn('mobile-filters__header')}>
            <h5>{t('pages.category.filters.title')}</h5>
            <CgClose size={32} onClick={() => setIsOpen(false)} />
          </div>
          <div className={cn('mobile-filters__body')}>
            <FilterBlock />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FilterButton;
