import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { CreatorCard } from 'components/uikit';

import { SearchCreatorsStore } from '../../store';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type CreatorsListProps = {
  data: SearchCreatorsStore['creators'];
  isLoading: SearchCreatorsStore['isFetching'];
};

const CreatorsList: FC<CreatorsListProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={cn('creators__list')}>
        {[...Array(6).keys()].map((item) => (
          <Skeleton key={item} height={500} borderRadius={12} />
        ))}
      </div>
    );
  }

  if (data === null || data.count === 0) {
    return <h5>{t('noData.search')}</h5>;
  }

  return (
    <div className={cn('creators__list')}>
      {data?.results?.map(
        ({
          id,
          price,
          media,
          name,
          platform,
          bio,
          rating,
          promoPrice,
          promoOff,
          username,
          counters,
        }) => (
          <CreatorCard
            key={id}
            priceInCents={price}
            promoPriceInCents={promoPrice}
            promoOff={promoOff}
            imageSrc={media?.avatar || null}
            platform={platform}
            likes={counters?.likes || null}
            name={name}
            username={username}
            bio={bio}
            rating={rating}
          />
        )
      )}
    </div>
  );
};

export default CreatorsList;
