import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CreatorCounters } from 'models';
import { formatNumber as commonFormatNumber } from 'utils';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type StatisticProps = {
  counters: CreatorCounters;
  className?: string;
};

const Statistic: FC<StatisticProps> = ({ counters, className }) => {
  const { t } = useTranslation();
  const { likes, photos, posts, videos, subscribers } = counters;

  const formatNumber = (num: number | null): string => {
    if (num) {
      return commonFormatNumber(num);
    }

    return t('common.na');
  };

  return (
    <div className={classNames(cn('stats'), className)}>
      <div>
        <h5>{formatNumber(likes)}</h5>
        <p>{t('pages.creator.statistics.likes')}</p>
      </div>
      <div>
        <h5>{formatNumber(photos)}</h5>
        <p>{t('pages.creator.statistics.photos')}</p>
      </div>
      <div>
        <h5>{formatNumber(posts)}</h5>
        <p>{t('pages.creator.statistics.posts')}</p>
      </div>
      <div>
        <h5>{formatNumber(videos)}</h5>
        <p>{t('pages.creator.statistics.videos')}</p>
      </div>
      <div>
        <h5>{formatNumber(subscribers)}</h5>
        <p>{t('pages.creator.statistics.fans')}</p>
      </div>
    </div>
  );
};

export default Statistic;
