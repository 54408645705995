import React from 'react';
import { useParams } from 'react-router-dom';

import CreatorDetailedInfoCards from 'modules/common/CreatorDetailedInfoCards';

import useCreatorStore, { useFetchDetailedCreator } from '../store';

const CreatorInfoBlock = () => {
  const { creatorUsername } = useParams();
  const { isLoading, data } = useFetchDetailedCreator(creatorUsername || '');

  return (
    <div className="wrapper">
      <CreatorDetailedInfoCards
        toggleFavorite={useCreatorStore.getState().toggleFavorite}
        isLoading={isLoading}
        data={data}
      />
    </div>
  );
};

export default CreatorInfoBlock;
