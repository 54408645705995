import React, { FC, PropsWithChildren } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { HighlightedText } from 'components/uikit';
import AuthAvatarsImg from 'assets/auth-avatars-image.png';

import styles from './styles.module.scss';
import classNames from 'classnames';

const cn = classNamesBind.bind(styles);

type AuthLayoutProps = {
  formClassName?: string;
  withInfo?: boolean;
};

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
  children,
  withInfo = true,
  formClassName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        cn('wrapper'),
        'wrapper wrapper--black-bg wrapper--with-spots'
      )}
    >
      <div className={cn('auth-layout')}>
        {withInfo && (
          <div className={cn('auth-layout__info')}>
            <div>
              <img src={AuthAvatarsImg} />
              <p>{t('components.authLayout.community')}</p>
            </div>
            <HighlightedText tag="h3">
              {t('components.authLayout.title')}
            </HighlightedText>
            <h5>{t('components.authLayout.subtitle')}</h5>
          </div>
        )}
        <div className={cn('auth-layout__form', formClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
