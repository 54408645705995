import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { CgMenuLeft, CgClose } from 'react-icons/cg';

import styles from '../styles.module.scss';

const cn = classNames.bind(styles);

type MobileMenuIconProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileMenuIcon: FC<MobileMenuIconProps> = ({ isOpen, setIsOpen }) => {
  const className = cn('header__burger-icon');

  return isOpen ? (
    <CgClose size={32} className={className} onClick={() => setIsOpen(false)} />
  ) : (
    <CgMenuLeft
      size={32}
      className={className}
      onClick={() => setIsOpen(true)}
    />
  );
};

export default MobileMenuIcon;
