import React from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import {
  VerificationForm,
  VerificationError,
  VerificationSuccess,
} from './components';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type VerificationProps = {
  show: 'form' | 'error' | 'success';
};

const Verification: React.FC<VerificationProps> = ({ show }) => {
  return (
    <div
      className={classNames(
        cn('verification-wrapper'),
        'wrapper wrapper--black-bg wrapper--with-spots'
      )}
    >
      <div className={cn('verification')}>
        {show === 'form' && <VerificationForm />}
        {show === 'error' && <VerificationError />}
        {show === 'success' && <VerificationSuccess />}
      </div>
    </div>
  );
};

export default Verification;
