import React from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBind from 'classnames/bind';
import { Link } from 'react-router-dom';

import ForCreatorsImage from 'assets/for-creators-image.png';
import { useAuthStore, useProfileStore } from 'stores';
import { HighlightedText } from 'components/uikit';

import styles from './styles.module.scss';
import { PATHS } from 'router';

const cn = classNamesBind.bind(styles);

const ForCreators = () => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => !!state.accessToken);
  const isVerified = useProfileStore((state) => !!state.profile?.creator?.id);

  return (
    <div className="wrapper wrapper--black-bg wrapper--with-spots">
      <div className={cn('for-creators')}>
        <div className={cn('for-creators__content')}>
          <HighlightedText tag="h1">
            {t('pages.forCreators.title')}
          </HighlightedText>
          <h5>{t('pages.forCreators.subtitle')}</h5>
          {isAuth ? (
            !isVerified && (
              <div className={cn('for-creators__buttons')}>
                <Link
                  to={PATHS.verification}
                  className="button button--primary-light"
                >
                  {t('actions.getVerified')}
                </Link>
              </div>
            )
          ) : (
            <div className={cn('for-creators__buttons')}>
              <Link to={PATHS.signUp} className="button button--primary-light">
                {t('actions.signUp')}
              </Link>
              <Link
                to={PATHS.signIn}
                className="button button--secondary-light"
              >
                {t('actions.login')}
              </Link>
            </div>
          )}
        </div>
        <div>
          <img src={ForCreatorsImage} alt={t('pages.forCreators.title')} />
        </div>
      </div>
    </div>
  );
};

export default ForCreators;
