import React, { ChangeEvent } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { CreatorSearchQueryParams } from 'models';
import { useQueryString } from 'hooks';
import { Switch } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const LocationSelectors = () => {
  const { t } = useTranslation();
  const { params, updateParams } = useQueryString<CreatorSearchQueryParams>();
  const { coordinates } = params;
  const isBrowserSupportLocationService = 'geolocation' in navigator;

  const handleUseCurrentLocation = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          const coordinates = `${latitude}, ${longitude}`;
          updateParams({ coordinates, offset: 0 });
        }
      );
    } else {
      updateParams({ coordinates: undefined, offset: 0 });
    }
  };

  if (!isBrowserSupportLocationService) {
    return null;
  }

  return (
    <div className={cn('filters__card')}>
      <p>{t('pages.category.filters.location')}</p>
      <Switch
        labelPosition="right"
        name="useCurrentLocation"
        defaultChecked={!!coordinates}
        onChange={handleUseCurrentLocation}
        label={t('pages.category.filters.useMyCurrentLocation')}
      />
      {/* <TextInput placeholder="Type location here" /> */}
    </div>
  );
};

export default LocationSelectors;
