import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { HighlightedText } from 'components/uikit';

import PaginatedReviewsLeftForMeList from './ReviewsLeftForMeList';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ReviewsLeftForMe = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <div className={cn('reviews')}>
        <HighlightedText tag="h3">
          {t('pages.reviewsLeftForMe.title')}
        </HighlightedText>
        <PaginatedReviewsLeftForMeList />
      </div>
    </div>
  );
};

export default ReviewsLeftForMe;
