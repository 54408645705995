import React from 'react';
import { useTranslation } from 'react-i18next';

import { Review } from 'components/uikit';
import { BREAKPOINTS } from 'styles/vars';

import SliderWithTitle from '../SliderWithTitle';
import { useFetchRecentReviews } from './store';

const RecentReviews = () => {
  const { t } = useTranslation();
  const title = t('pages.home.recentReviews');
  const { isLoading, data } = useFetchRecentReviews({ limit: 6, offset: 0 });

  return (
    <SliderWithTitle
      title={title}
      grayBg
      showNoData={!isLoading && (data === null || data?.count === 0)}
      sliderProps={{
        isLoading: isLoading,
        slidesToShow: 4.4,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3.4,
            },
          },
          {
            breakpoint: BREAKPOINTS.L,
            settings: {
              slidesToShow: 2.4,
            },
          },
          {
            breakpoint: BREAKPOINTS.M,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: BREAKPOINTS.S,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      }}
    >
      {data?.results.map((review) => (
        <Review key={review.id} review={review} canRate={false} />
      ))}
    </SliderWithTitle>
  );
};

export default RecentReviews;
