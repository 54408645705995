import React, { FC, Fragment } from 'react';
import classNamesBind from 'classnames/bind';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'router';
// import { Divider } from 'components/uikit';
import AuthLayout from 'modules/Auth/AuthLayout';
// import FacebookLogo from 'assets/facebook.svg';
// import GoogleLogo from 'assets/google.svg';

import SignUpInForm from './SignUpInForm';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type SignUpInProps = {
  isSignIn?: boolean;
};

const SignUpIn: FC<SignUpInProps> = ({ isSignIn = true }) => {
  const { t } = useTranslation();

  return (
    <AuthLayout formClassName={cn('sign-in-up__form')}>
      <h5>{isSignIn ? t('actions.login') : t('actions.signUp')}</h5>
      <p>
        {isSignIn ? (
          <Fragment>
            {t('pages.auth.dontHaveAccount')}{' '}
            <Link to={PATHS.signUp}>{t('actions.signUp')}</Link>
          </Fragment>
        ) : (
          <Fragment>
            {t('pages.auth.haveAccount')}{' '}
            <Link to={PATHS.signIn}>{t('actions.login')}</Link>
          </Fragment>
        )}
      </p>
      <SignUpInForm isSignIn={isSignIn} />
      {/* <Divider text="or" />
      <div className={cn('sign-in-up__third-party-services')}>
        <button className="button--secondary">
          <img src={GoogleLogo} />
          <p>{t('thirdPartyServices.google')}</p>
        </button>
        <button className="button--secondary">
          <img src={FacebookLogo} />
          <p>{t('thirdPartyServices.facebook')}</p>
        </button>
      </div> */}
      {!isSignIn && (
        <p className={cn('sign-in-up__policy-text')}>
          {t('pages.auth.termsText')}
          <Link to={PATHS.terms}>{t('pages.terms.fullName')}</Link>{' '}
          {t('common.and')}{' '}
          <Link to={PATHS.privacy}>{t('pages.privacy.name')}</Link>.
        </p>
      )}
    </AuthLayout>
  );
};

export default SignUpIn;
