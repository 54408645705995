import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PATHS } from 'router';

const VerificationSuccess = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <h5>{t('pages.verification.success.title')}</h5>
      <p>{t('pages.verification.success.subtitle')}</p>
      <Link className="button--primary" to={PATHS.myProfile}>
        {t('pages.account.name')}
      </Link>
    </Fragment>
  );
};

export default VerificationSuccess;
