import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { Link, LinkProps, To } from 'react-router-dom';
import { IoIosStar } from 'react-icons/io';
import { PiHeart } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

import { Platforms } from 'models';
import { Image } from 'components/uikit';
import { formatNumber, getPlatformColorLogo, round } from 'utils';

import Price, { PriceProps } from '../Price';
import styles from './styles.module.scss';
import { PATHS } from 'router';

const cn = classNamesBind.bind(styles);

type CreatorCardProps = Omit<LinkProps, 'to'> &
  PriceProps & {
    rating: number | null;
    imageSrc: string | null;
    platform: Platforms | null;
    likes: number | null;
    name: string | null;
    username: string | null;
    bio: string | null;
    to?: To;
  };

const CreatorCard: FC<CreatorCardProps> = ({
  className,
  rating,
  imageSrc,
  platform,
  priceInCents,
  promoOff,
  promoPriceInCents,
  name,
  username,
  likes,
  bio,
  to,
  ...rest
}) => {
  const { t } = useTranslation();
  const cleanedBio = bio?.replace(/<\/?[^>]+(>|$)/g, '');
  const path = to || (username ? PATHS.getCreator(username) : '#');

  return (
    <Link
      {...rest}
      to={path}
      className={classNames(cn('creator-card'), className)}
    >
      <div>
        <div className={cn('creator-card__image-wrapper')}>
          <Image className={cn('creator-card__image')} src={imageSrc} />
          {rating !== null && rating !== 0 && (
            <span>
              <IoIosStar size={20} />
              <p>{round(rating)}</p>
            </span>
          )}
        </div>
        <div className={cn('creator-card__header')}>
          <div>
            {username && <p>{username}</p>}
            {name && <h5>{name}</h5>}
          </div>
          {platform && (
            <img
              src={getPlatformColorLogo(platform)}
              alt={platform.toString()}
            />
          )}
        </div>
        {cleanedBio && <p>{cleanedBio}</p>}
      </div>
      <div className={cn('creator-card__stats')}>
        {likes ? (
          <div>
            <PiHeart size={24} />
            <p>
              {formatNumber(likes)} {t('components.creatorCard.likes')}
            </p>
          </div>
        ) : (
          <div />
        )}
        <Price
          promoOff={promoOff}
          priceInCents={priceInCents}
          promoPriceInCents={promoPriceInCents}
        />
      </div>
    </Link>
  );
};

export default CreatorCard;
