import React, { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthStore, useProfileStore } from 'stores';
import { PATHS } from 'router';

import { navLinkClassName } from '../Menu/helpers';

type AuthMenuItemsProps = {
  close: () => void;
};

const AuthMenuItems: FC<AuthMenuItemsProps> = ({ close }) => {
  const { t } = useTranslation();
  const logout = useAuthStore((state) => state.logout);
  const isCreator = useProfileStore((state) => !!state.profile?.creator?.id);

  return (
    <Fragment>
      <NavLink
        className={navLinkClassName}
        onClick={close}
        to={PATHS.myProfile}
      >
        {t('components.header.menu.account')}
      </NavLink>
      <NavLink
        className={navLinkClassName}
        onClick={close}
        to={PATHS.reviewsForOthers}
      >
        {t('components.header.menu.reviewsForOthers')}
      </NavLink>
      {isCreator && (
        <NavLink
          className={navLinkClassName}
          onClick={close}
          to={PATHS.reviewsLeftForMe}
        >
          {t('components.header.menu.reviewsLeftForMe')}
        </NavLink>
      )}

      <NavLink
        className={navLinkClassName}
        onClick={close}
        to={PATHS.myFavorites}
      >
        {t('components.header.menu.favorites')}
      </NavLink>
      <p
        onClick={() => {
          logout();
          close();
        }}
      >
        {t('actions.signOut')}
      </p>
    </Fragment>
  );
};

export default AuthMenuItems;
