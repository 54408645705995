import React, { FC, PropsWithChildren, JSX, HTMLAttributes } from 'react';
import parse from 'html-react-parser';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type ParsedHtmlProps = HTMLAttributes<HTMLOrSVGElement> & {
  tag: keyof JSX.IntrinsicElements;
};

const ParsedHtml: FC<PropsWithChildren<ParsedHtmlProps>> = ({
  tag: Wrapper = 'h1',
  children,
  className,
  ...rest
}) => {
  const html = parse(children?.toString() || '');

  return (
    <Wrapper {...rest} className={classNames(className, cn('parsed-html'))}>
      {html}
    </Wrapper>
  );
};

export default ParsedHtml;
