import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { TextInput, TextInputProps } from '../uikit';
import { DefaultInputProps } from './types';

const TextInputController = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultInputProps<FormValues, TextInputProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  return <TextInput {...field} error={error} {...componentProps} />;
};

export default TextInputController;
