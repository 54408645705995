import React, { forwardRef, InputHTMLAttributes, ReactElement } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { InputProps } from 'types';

const cn = classNamesBind.bind(styles);

export type ToggleButtonProps = InputHTMLAttributes<HTMLInputElement> &
  Pick<InputProps, 'className' | 'label'> & {
    type?: 'checkbox' | 'radio';
    rightEl?: ReactElement;
    leftEl?: ReactElement;
  };

// eslint-disable-next-line react/display-name
const ToggleButton = forwardRef<HTMLInputElement, ToggleButtonProps>(
  (props, ref) => {
    const {
      className,
      label,
      value,
      type = 'checkbox',
      rightEl,
      leftEl,
      ...rest
    } = props;

    return (
      <label className={classNames(cn('toggle-button', className))}>
        <input type={type} value={value} {...rest} ref={ref} />
        <span>
          {leftEl && leftEl}
          <p>{label || value}</p>
          {rightEl && rightEl}
        </span>
      </label>
    );
  }
);

export default ToggleButton;
