import React, { ChangeEvent, useCallback } from 'react';
import { useController, FieldValues } from 'react-hook-form';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import Avatar, { AvatarProps } from '../../uikit/Avatar';
import { DefaultInputProps } from '../types';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type AvatarInputProps = AvatarProps & { label?: string };

const AvatarInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultInputProps<FormValues, AvatarInputProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange, ...otherFieldProps } = field;
  const avatarSrc =
    typeof value === 'string' ? value : URL.createObjectURL(value);
  const fileName = value?.name;

  const onHandleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files.length === 0) return;

    const file = files[0];

    onChange(file);
  }, []);

  const { label, ...avatarProps } = componentProps || {};

  return (
    <div className={cn('avatar-input')}>
      <label>
        {label && <p>{label}</p>}
        <div>
          <input
            {...otherFieldProps}
            value={value?.fileName}
            onChange={onHandleChange}
            type="file"
            accept="image/*"
          />
          <Avatar
            {...avatarProps}
            src={avatarSrc}
            className={classNames(cn('avatar'), componentProps?.className)}
          />
          {fileName && <p>{fileName}</p>}
        </div>
      </label>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default AvatarInput;
