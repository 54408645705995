import React from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { HighlightedText } from 'components/uikit';

import styles from './styles.module.scss';
import FavoritesList from './FavoritesList';

const cn = classNamesBind.bind(styles);

const MyFavorites = () => {
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <div className={cn('favorites')}>
        <HighlightedText tag="h3">
          {t('pages.myFavorites.title')}
        </HighlightedText>
        <FavoritesList />
      </div>
    </div>
  );
};

export default MyFavorites;
