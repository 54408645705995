import React from 'react';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError, Link } from 'react-router-dom';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { useQueryString } from 'hooks';

import { ErrorPageQueryParams } from './types';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const Error = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  const { params } = useQueryString<ErrorPageQueryParams>();
  const isRouteError = isRouteErrorResponse(error);
  const defaultError = t('errors.api.other');

  let { status, message } = params;

  if (isRouteError) {
    status = error.status;
    message = error.statusText || error.data.message;
  }

  return (
    <div className={classNames(cn('error-page'), 'wrapper')}>
      <div>
        <h3>Oops! {status && status}</h3>
        <h5>{message || defaultError}</h5>
        <Link to="/">{t('pages.home.name')}</Link>
      </div>
    </div>
  );
};

export default Error;
