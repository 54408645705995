export default function formatNumber(
  num: number,
  precision: number = 1
): string {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const { threshold, suffix } = found;
    const fractionDigits = threshold === 1 ? 0 : precision;
    const formatted = (num / threshold).toFixed(fractionDigits) + suffix;
    return formatted;
  }

  return num.toString();
}
