import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBind from 'classnames/bind';
import { useForm, useWatch, Control } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextInput } from 'components/form';
import { HighlightedText, CircularIndicator } from 'components/uikit';

import styles from '../styles.module.scss';
import { useProfileStore } from 'stores';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'router';

const cn = classNamesBind.bind(styles);

const schema = yup.object({
  username: yup.string().min(3).max(50).required(),
});

type FormData = yup.InferType<typeof schema>;

type LinkBlockProps = {
  control: Control<FormData>;
};

const origin = window.location.origin + '/';

const LinkBlock = ({ control }: LinkBlockProps) => {
  const { t } = useTranslation();
  const username = useWatch({
    control,
    name: 'username',
  });

  const link = origin + (username || '');
  const copyText = t('pages.verification.checkoutReview', {
    link,
    interpolation: { escapeValue: false },
  });

  return (
    <div className={cn('verification__block')}>
      <p>{t('pages.verification.step3')}</p>
      <p className={cn('verification__subtitle')}>{copyText}</p>
      <button
        type="button"
        className="button--secondary"
        onClick={() => navigator.clipboard.writeText(copyText)}
      >
        {t('actions.copyToClipboard')}
      </button>
    </div>
  );
};

const VerificationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({ resolver: yupResolver(schema), mode: 'all' });

  const isButtonDisabled = !isValid || isSubmitting;
  const onSubmit = handleSubmit(async (values) => {
    const success = await useProfileStore.getState().verifyProfile(values);
    const path = success ? PATHS.verificationSuccess : PATHS.verificationError;
    navigate(path);
  });

  return (
    <Fragment>
      <h5>{t('actions.getVerified')}</h5>
      <form onSubmit={onSubmit}>
        <div className={cn('verification__block')}>
          <p>{t('pages.verification.step1')}</p>
          <div className={cn('verification__link-input')}>
            <p>{origin}</p>
            <TextInput
              name="username"
              control={control}
              componentProps={{
                placeholder: t('inputs.username'),
                underlined: true,
              }}
            />
          </div>
        </div>
        <HighlightedText tag="p">
          {t('pages.verification.step2')}
        </HighlightedText>
        <LinkBlock control={control} />
        <p>{t('pages.verification.step4')}</p>
        <button
          type="submit"
          className="button--primary"
          disabled={isButtonDisabled}
        >
          {t('actions.verify')}
          {isSubmitting && <CircularIndicator />}
        </button>
      </form>
    </Fragment>
  );
};

export default VerificationForm;
