import React from 'react';
import {
  useController,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';

import { ToggleButtonGroup, ToggleButtonGroupProps } from '../uikit';

type ToggleButtonGroupControllerProps<FormValues extends FieldValues> =
  UseControllerProps<FormValues> & {
    items: ToggleButtonGroupProps['items'];
    componentProps?: Omit<ToggleButtonGroupProps, 'items'>;
  };

const ToggleButtonGroupController = <FormValues extends FieldValues>({
  componentProps,
  items,
  ...input
}: ToggleButtonGroupControllerProps<FormValues>) => {
  const { field, fieldState } = useController<FormValues>(input);

  const error = fieldState.error?.message;

  const { onChange, ...restFieldProps } = field;

  const onChangeHandler = (values: string[]) => {
    onChange(values);
    componentProps?.onHandleChange && componentProps.onHandleChange(values);
  };

  return (
    <ToggleButtonGroup
      items={items}
      {...restFieldProps}
      error={error}
      {...componentProps}
      onHandleChange={onChangeHandler}
    />
  );
};

export default ToggleButtonGroupController;
