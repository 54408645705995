import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Platforms,
  CreatorSearchQueryParams,
  CreatorRating,
  CreatorPrice,
  CreatorGender,
} from 'models';
import { useQueryString } from 'hooks';
import { Switch, ToggleButtonGroup } from 'components/uikit';

import {
  platformItems,
  ratingItems,
  genderItems,
  priceItems,
} from './constants';
import LocationSelectors from './LocationSelectors';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type FilterBlockProps = {
  className?: string;
};

const FilterBlock: FC<FilterBlockProps> = ({ className }) => {
  const { t } = useTranslation();
  const { params, updateParams } = useQueryString<CreatorSearchQueryParams>();
  const { nsfw, platform, rating, price, gender } = params;

  return (
    <div className={classNames(cn('filters'), className)}>
      <div className={cn('filters__card')}>
        <Switch
          name="NSFW"
          label="View NSFW"
          defaultChecked={nsfw ?? true}
          labelPosition="right"
          onChange={({ target: { checked } }) =>
            updateParams({ nsfw: checked, offset: 0 })
          }
        />
      </div>
      <div className={cn('filters__card')}>
        <p>{t('pages.category.filters.platforms')}</p>
        <ToggleButtonGroup
          name="platforms"
          type="radio"
          onHandleChange={(values) =>
            updateParams({ platform: values[0] as Platforms, offset: 0 })
          }
          defaultValue={platform}
          items={platformItems}
        />
      </div>
      <div className={cn('filters__card')}>
        <p>{t('pages.category.filters.rating')}</p>
        <ToggleButtonGroup
          type="radio"
          name="rating"
          defaultValue={rating}
          onHandleChange={(values) =>
            updateParams({ rating: values[0] as CreatorRating, offset: 0 })
          }
          items={ratingItems}
        />
      </div>
      <div className={cn('filters__card')}>
        <p>{t('pages.category.filters.price')}</p>
        <ToggleButtonGroup
          type="radio"
          name="price"
          items={priceItems}
          defaultValue={price}
          onHandleChange={(values) =>
            updateParams({ price: values[0] as CreatorPrice, offset: 0 })
          }
        />
      </div>
      <div className={cn('filters__card')}>
        <p>{t('pages.category.filters.gender')}</p>
        <ToggleButtonGroup
          type="radio"
          name="gender"
          items={genderItems}
          defaultValue={gender}
          onHandleChange={(values) =>
            updateParams({ gender: values[0] as CreatorGender, offset: 0 })
          }
        />
      </div>
      <LocationSelectors />
      {/* <div className={cn('filters__card')}>
        <p>{t('pages.category.filters.relatedSearches')}</p>
        <div className={cn('filters__links')}>
          <a href="#">Clairepie</a>
          <a href="#">Allentown</a>
          <a href="#">Basia92</a>
        </div>
      </div> */}
    </div>
  );
};

export default FilterBlock;
