import { NavLinkProps } from 'react-router-dom';
import classNamesBind from 'classnames/bind';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export const navLinkClassName: NavLinkProps['className'] = ({ isActive }) =>
  cn('menu__link', {
    'menu__link--active': isActive,
  });
