import { useSearchParams } from 'react-router-dom';

import { queryString } from 'utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useQueryString<ParamsT extends Record<string, any>>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = queryString.parse(searchParams.toString()) as ParamsT;

  const updateParams = (value: ParamsT) => {
    const newParams: ParamsT = {
      ...params,
      ...value,
    };
    const newParamsString = queryString.stringify(newParams);
    const searchParams = new URLSearchParams(newParamsString);

    setSearchParams(searchParams);
  };

  return { params, updateParams };
}

export default useQueryString;
