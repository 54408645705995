import React, { InputHTMLAttributes, useState, forwardRef } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { IoEye, IoEyeOff } from 'react-icons/io5';

import styles from './styles.module.scss';
import { InputProps } from 'types';

const cn = classNamesBind.bind(styles);

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> &
  InputProps & {
    underlined?: boolean;
    type?:
      | 'email'
      | 'hidden'
      | 'number'
      | 'password'
      | 'search'
      | 'tel'
      | 'text'
      | 'url';
  };

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { type = 'text', label, underlined = false, error, className, ...rest },
    ref
  ) => {
    const isPasswordType = type === 'password';
    const [showPassword, setShowPassword] = useState(false);
    const ShowPasswordIcon = showPassword ? IoEyeOff : IoEye;

    return (
      <div
        className={classNames(
          cn('text-input', { 'text-input--underlined': underlined }, className)
        )}
      >
        {label && <label>{label}</label>}
        <input
          ref={ref}
          {...rest}
          type={isPasswordType ? (showPassword ? 'text' : 'password') : type}
        />
        {error && <p className="error-text">{error}</p>}
        {isPasswordType && (
          <ShowPasswordIcon
            onClick={() => setShowPassword((state) => !state)}
          />
        )}
      </div>
    );
  }
);

export default TextInput;
