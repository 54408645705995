import React, { FC, InputHTMLAttributes } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type SwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  className?: string;
  name: string;
  labelPosition?: 'left' | 'right';
};

const Switch: FC<SwitchProps> = ({
  label,
  name,
  className,
  labelPosition = 'left',
  ...rest
}) => {
  return (
    <label className={classNames(cn('switch'), className)}>
      {labelPosition === 'left' && label && <p>{label}</p>}
      <div>
        <input {...rest} name={name} type="checkbox" />
        <span />
      </div>
      {labelPosition === 'right' && label && <p>{label}</p>}
    </label>
  );
};

export default Switch;
