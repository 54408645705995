import React from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { motion } from 'framer-motion';

const CircularIndicator = () => {
  return (
    <motion.div
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImSpinner8 />
    </motion.div>
  );
};

export default CircularIndicator;
