import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import { IoPersonOutline } from 'react-icons/io5';

import { Image, ImageProps } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

export type AvatarProps = Omit<ImageProps, 'src'> & {
  src?: ImageProps['src'];
};

const Avatar: FC<AvatarProps> = ({ src = '', className, ...rest }) => {
  return (
    <Image
      emptyOrErrorComponent={<IoPersonOutline />}
      src={src}
      {...rest}
      className={classNames(cn('avatar'), className)}
    />
  );
};

export default Avatar;
