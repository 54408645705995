import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { useFetchReviewsForOthers, useMyReviewsStore } from 'stores';
import { Review, Paginate } from 'components/uikit';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type ReviewsForOthersListProps = ReturnType<typeof useFetchReviewsForOthers>;

const ReviewsForOthersList: FC<ReviewsForOthersListProps> = ({
  data,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={cn('reviews__list')}>
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
        <Skeleton height={220} width="100%" borderRadius={16} />
      </div>
    );
  }

  if (data === null || data.count === 0) {
    return <h5>{t('noData.reviewsForOthers')}</h5>;
  }
  const items = data.results;

  return (
    <div className={cn('reviews__list')}>
      {items.map((review) => (
        <Review key={review.id} canRate={false} review={review} />
      ))}
    </div>
  );
};

const PaginatedReviewsForOthersList = () => {
  const { data, isLoading } = useFetchReviewsForOthers();

  const handlePageClick = ({ selected }: { selected: number }) => {
    const store = useMyReviewsStore.getState();
    const limit = store.reviewsForOthers?.limit || 0;
    const offset = limit * selected;

    store.fetchReviewsForOthers({ offset }, { showLoader: false });
  };

  return (
    <div>
      <ReviewsForOthersList data={data} isLoading={isLoading} />
      <Paginate onPageChange={handlePageClick} itemsCount={data?.count || 0} />
    </div>
  );
};

export default PaginatedReviewsForOthersList;
