import React, { FC } from 'react';
import classNamesBind from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { round } from 'utils';

import { Rating } from 'components/uikit';

import useCreatorStore from '../../store';
import StarStatistic from '../StarStatistic';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ReviewsStatisticBlock: FC = () => {
  const { t } = useTranslation();
  const isLoading = useCreatorStore((state) => state.isCreatorFetching);
  const rating = useCreatorStore(
    (state) => state.creator?.reviewsStatistic?.rating || 0
  );
  const reviewCount = useCreatorStore(
    (state) => state.creator?.reviewsStatistic?.reviewCount || 0
  );
  const ratingBreakdown = useCreatorStore(
    (state) => state.creator?.reviewsStatistic?.ratingBreakdown || null
  );

  if (isLoading) {
    return <div />;
  }

  const roundedRating = round(rating) || 0;

  return (
    <div className={cn('reviews-statistic-block')}>
      <div>
        <h2>{roundedRating}</h2>
        <div className={cn('reviews-statistic-block__rating')}>
          <Rating clickable={false} value={rating} size="large" />
          <p>{t('pages.creator.fromReviews', { total: reviewCount })}</p>
        </div>
      </div>
      {ratingBreakdown && (
        <div className={cn('reviews-statistic-block__stars-wrapper')}>
          <div className={cn('reviews-statistic-block__stars')}>
            <StarStatistic
              star={5}
              total={reviewCount}
              current={ratingBreakdown[5] || 0}
            />
            <StarStatistic
              star={4}
              total={reviewCount}
              current={ratingBreakdown[4] || 0}
            />
            <StarStatistic
              star={3}
              total={reviewCount}
              current={ratingBreakdown[3] || 0}
            />
          </div>
          <div className={cn('reviews-statistic-block__stars')}>
            <StarStatistic
              star={2}
              total={reviewCount}
              current={ratingBreakdown[2] || 0}
            />
            <StarStatistic
              star={1}
              total={reviewCount}
              current={ratingBreakdown[1] || 0}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsStatisticBlock;
