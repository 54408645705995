import React from 'react';
import classNamesBind from 'classnames/bind';

import CountBlock from '../CountBlock';
import FilterBlock from '../FilterBlock';
import CreatorsBlock from '../CreatorsBlock/CreatorsBlock';
import FilterButton from '../FilterButton';
import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

const ContentBlock: React.FC = () => {
  return (
    <div className="wrapper">
      <CountBlock />
      <div className={cn('content')}>
        <FilterBlock className={cn('content__filters')} />
        <FilterButton />
        <CreatorsBlock />
      </div>
    </div>
  );
};

export default ContentBlock;
