import React, { Fragment } from 'react';

import {
  BrowseBlock,
  OnlyfansBlock,
  PatreonBlock,
  TwitchBlock,
} from './components';

const Categories = () => {
  return (
    <Fragment>
      <BrowseBlock />
      <OnlyfansBlock />
      <PatreonBlock />
      <TwitchBlock />
    </Fragment>
  );
};

export default Categories;
