import {
  categoriesApi,
  GetCategoriesParams,
  GetCategoriesReturn,
} from 'api/repositories/categories';
import { createFetchStore } from 'utils';

export const {
  useFetchStore: useCategoriesStore,
  useFetchData: useFetchCategories,
} = createFetchStore<GetCategoriesReturn, GetCategoriesParams>(
  categoriesApi.getList
);
