import React, { Fragment } from 'react';

import { Header, ContentBlock } from './components';
import { useFetchCreatorsWhenQueryParamsChange } from './store';

const SearchCreators = () => {
  useFetchCreatorsWhenQueryParamsChange();

  return (
    <Fragment>
      <Header />
      <ContentBlock />
    </Fragment>
  );
};

export default SearchCreators;
