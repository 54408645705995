import { useEffect, useRef, RefObject } from 'react';

export default function useOutsideTap<T extends HTMLElement>(
  when: boolean,
  outsideTap: () => void
): RefObject<T> {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handler: EventListenerOrEventListenerObject = (event) => {
      if (when && ref.current && !ref.current.contains(event.target as Node)) {
        outsideTap();
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [when]);

  return ref;
}
