import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { Profile } from 'models';
import { TextInput, AvatarInput } from 'components/form';
import { CircularIndicator } from 'components/uikit';
import { useProfileStore } from 'stores';
import { UpdateMyProfileBody } from 'api';

type MainProfileInfoFormProps = Pick<Profile, 'name' | 'email' | 'avatarUrl'>;

const schema = yup.object().shape({
  avatar: yup.mixed().optional(),
  email: yup.string().email().required(),
  name: yup.string().nullable().required().min(3).max(50),
});

type FormData = yup.InferType<typeof schema>;

const MainProfileInfoForm: FC<MainProfileInfoFormProps> = ({
  name,
  email,
  avatarUrl,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      avatar: avatarUrl ?? '',
      email: email ?? '',
      name: name ?? '',
    },
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(async ({ name, avatar }) => {
    const body: UpdateMyProfileBody = { name };
    const shouldUpdateAvatar = avatar && typeof avatar !== 'string';

    if (shouldUpdateAvatar) {
      body.avatar = avatar as File;
    }

    await useProfileStore.getState().updateProfile(body);
  });

  return (
    <form onSubmit={onSubmit}>
      <AvatarInput
        name="avatar"
        control={control}
        componentProps={{ label: t('inputs.avatar') }}
      />
      <TextInput
        name="name"
        control={control}
        componentProps={{
          type: 'text',
          label: t('inputs.username'),
          placeholder: t('inputs.username'),
        }}
      />
      <TextInput
        name="email"
        control={control}
        componentProps={{
          type: 'email',
          disabled: true,
          readOnly: true,
          label: t('inputs.email'),
          placeholder: 'test@test.test',
        }}
      />
      <button
        type="submit"
        disabled={isButtonDisabled}
        className="button--primary"
      >
        {t('actions.apply')}
        {isSubmitting && <CircularIndicator />}
      </button>
    </form>
  );
};

export default MainProfileInfoForm;
