import React, { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import styles from './styles.module.scss';

const cn = classNamesBind.bind(styles);

type CategoryTagProps = LinkProps & {
  className?: string;
  emoji?: string | null;
};

const CategoryTag: FC<PropsWithChildren<CategoryTagProps>> = ({
  className,
  children,
  emoji,
  ...rest
}) => {
  return (
    <Link
      {...rest}
      className={classNames(
        cn('category-tag', { 'category-tag--with-emoji': emoji }),
        className
      )}
    >
      {emoji && <span>{emoji}</span>}
      <p>{children}</p>
    </Link>
  );
};

export default CategoryTag;
