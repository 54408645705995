import http from '../http';
import { Profile } from 'models';

export type UpdateMyProfileBody = {
  name?: string;
  enableEmailNotifications?: boolean;
  avatar?: File;
};

class UsersApi {
  my = async (): Promise<Profile | null> =>
    http.deprecatedFetch((axios, signal) => axios.get('/users/my', { signal }));

  updateMy = async (body: UpdateMyProfileBody): Promise<Profile | null> => {
    const formData = new FormData();

    Object.entries(body).forEach(([key, value]) => {
      if (key && value !== null && value !== undefined) {
        const correctFormDataType =
          typeof value === 'string' || value instanceof Blob;
        const formDataValue = correctFormDataType ? value : `${value}`;
        formData.set(key, formDataValue);
      }
    });

    return http.deprecatedFetch((axios, signal) =>
      axios.patch('/users/my', formData, { signal })
    );
  };
}

export const usersApi = new UsersApi();
