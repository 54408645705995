import { reviewsApi, GetReviewsParams } from 'api';
import { Paginate, Review } from 'models';
import { createFetchStore } from 'utils';

export const {
  useFetchStore: useRecentReviewsStore,
  useFetchData: useFetchRecentReviews,
} = createFetchStore<Paginate<Review> | null, GetReviewsParams>(
  async (params) => (await reviewsApi.recent(params)).data
);
