import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { enqueueSnackbar } from 'notistack';

import storage from 'services/storage';
import {
  usersApi,
  authApi,
  verificationApi,
  VerifyProfileBody,
  UpdateMyProfileBody,
} from 'api';
import { Profile } from 'models';
import i18n from 'lang';

type State = {
  profile: Profile | null;
  isLoading: boolean;
};

type Actions = {
  clear: () => void;
  fetch: () => Promise<void>;
  changePassword: typeof authApi.changePassword;
  verifyProfile: (body: VerifyProfileBody) => Promise<boolean>;
  updateProfile: (body: UpdateMyProfileBody) => Promise<void>;
  setLoading: (value: boolean) => void;
  setProfile: (profile: Profile | null) => void;
};

export type ProfileStore = State & Actions;

const defaultState: State = {
  profile: null,
  isLoading: false,
};

const useProfileStore = create<ProfileStore>()(
  persist(
    immer((set, get) => ({
      ...defaultState,

      clear: () => set(() => defaultState),

      setLoading: (value) =>
        set((state) => {
          state.isLoading = value;
        }),

      setProfile: (newProfile) =>
        set((state) => {
          state.profile = newProfile;
        }),

      fetch: async () => {
        const { setLoading, setProfile } = get();

        setLoading(true);

        const res = await usersApi.my();

        setLoading(false);

        if (res !== null) {
          setProfile(res);
        }
      },

      changePassword: async (body) => {
        const res = await authApi.changePassword(body);

        if (res !== null) {
          enqueueSnackbar(i18n.t('notify.changePassword'), {
            variant: 'success',
            hideIconVariant: true,
          });
        }

        return res;
      },

      updateProfile: async (body) => {
        const res = await usersApi.updateMy(body);

        if (res === null) return;

        get().setProfile(res);

        enqueueSnackbar(i18n.t('notify.updateMyProfile'), {
          variant: 'success',
          hideIconVariant: true,
        });
      },

      verifyProfile: async (body) => {
        const res = await verificationApi.verify(body);

        if (res === null) {
          return false;
        }

        const success = res.success || false;

        if (success && res.creator?.id) {
          set((state) => {
            if (state.profile) {
              state.profile.creator = res.creator;
            }
          });
        }

        return success;
      },
    })),
    {
      name: storage.PROFILE_STORE_KEY,
    }
  )
);

export default useProfileStore;
