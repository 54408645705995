import React, { Fragment } from 'react';

import { ReviewsBlock, CreatorInfoBlock } from './components';

const Creator = () => {
  return (
    <Fragment>
      <CreatorInfoBlock />
      <ReviewsBlock />
    </Fragment>
  );
};

export default Creator;
