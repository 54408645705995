import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';

import { useDisableOverflowY } from 'hooks';

import styles from './styles.module.scss';
import ReplyDialog from './ReplyDialog';

const cn = classNamesBind.bind(styles);

export type ReplyButtonProps = {
  onReply: ({ comment }: { comment: string }) => Promise<void>;
};

const ReplyButton: FC<ReplyButtonProps> = ({ onReply }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  useDisableOverflowY(isOpen);

  return (
    <Fragment>
      <button
        className={classNames(cn('reply-button'), 'button', 'button--primary')}
        onClick={() => setIsOpen(true)}
      >
        {t('components.review.reply')}
      </button>
      {isOpen && <ReplyDialog setIsOpen={setIsOpen} onReply={onReply} />}
    </Fragment>
  );
};

export default ReplyButton;
