import axios, { AxiosInstance, AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';

import i18n from 'lang';
import { useAuthStore } from 'stores';

import { ApiCall, ApiResponse } from './types';

class Http {
  axios: AxiosInstance;
  controller = new AbortController();

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 10000,
    });

    this.axios.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        const res = error.response;
        const status = res?.status;

        if (status === 401) {
          useAuthStore.getState().refresh();
        }

        return Promise.reject(error);
      }
    );
  }

  setAuthTokenToHeaders(token: string): void {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  clearAuthTokenFromHeaders(): void {
    this.axios.defaults.headers.common['Authorization'] = '';
  }

  async fetch<ReturnType>(
    apiCall: ApiCall<ReturnType>,
    showError: boolean = true
  ): Promise<ApiResponse<ReturnType>> {
    try {
      const { data } = await apiCall(this.axios, this.controller.signal);

      return { data, error: null };
    } catch (error) {
      let message = i18n.t('errors.api.other');
      let err: AxiosError<ReturnType> = { message } as AxiosError<ReturnType>;

      if (error instanceof AxiosError) {
        const res = error.response;
        message = res?.data?.message || error.message;
        err = error;
      }

      if (showError) {
        enqueueSnackbar(message, {
          variant: 'error',
          hideIconVariant: true,
        });
      }

      return {
        data: null,
        error: err,
      };
    }
  }

  async deprecatedFetch<ReturnType>(
    apiCall: ApiCall<ReturnType>,
    showError: boolean = true
  ): Promise<ReturnType | null> {
    try {
      const { data } = await apiCall(this.axios, this.controller.signal);

      return data;
    } catch (error) {
      let message = i18n.t('errors.api.other');

      if (error instanceof AxiosError) {
        const res = error.response;
        message = res?.data?.message || error.message;
      }

      if (showError) {
        enqueueSnackbar(message, {
          variant: 'error',
          hideIconVariant: true,
        });
      }

      return null;
    }
  }
}

const http = new Http();

export default http;
