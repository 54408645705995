import React, { FC, Dispatch, SetStateAction, Fragment } from 'react';

import classNamesBind from 'classnames/bind';

import { useOutsideTap } from 'hooks';
import { Avatar } from 'components/uikit';

import styles from './styles.module.scss';
import AuthMenuItems from './AuthMenuItems';
import { useProfileStore } from 'stores';

const cn = classNamesBind.bind(styles);

type AvatarWithMenuProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AvatarWithMenu: FC<AvatarWithMenuProps> = ({ isOpen, setIsOpen }) => {
  const close = () => setIsOpen(false);
  const ref = useOutsideTap<HTMLDivElement>(isOpen, close);
  const avatarUrl = useProfileStore(
    (state) => state.profile?.avatarUrl || null
  );

  return (
    <Fragment>
      <Avatar
        src={avatarUrl}
        className={cn('avatar')}
        onClick={() => setIsOpen(true)}
      />
      <div
        ref={ref}
        className={cn('avatar__menu', {
          'avatar__menu--active': isOpen,
        })}
      >
        <AuthMenuItems close={close} />
      </div>
    </Fragment>
  );
};

export default AvatarWithMenu;
